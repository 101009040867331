import { inject } from "react-ioc";
import { NamedIdApiModel } from "../../common/api-utils";
import ClientApi, { ClientApiModel, BillInstructions } from "../api/client.api";

export type Client = {
    id: number;
    name: string;
    displayName: string;

    address?: string;
    chargeable: boolean;

    taskTypeNames: string[];

    billInstructions: BillInstructions;
    customBillingInstructions: string | undefined;
};

export type TaskType = NamedIdApiModel;

export class ClientDataStore {
    @inject private api!: ClientApi;

    private taskNames = new Map<string, number>();
    private taskIds = new Map<number, string>();

    get = async (): Promise<{ clients: Client[]; taskTypes: TaskType[] }> => {
        const { data } = await this.api.get();

        this.taskNames = new Map(data.taskTypes.map((x) => [x.name, x.id as number]));
        this.taskIds = new Map(data.taskTypes.map((x) => [x.id as number, x.name]));

        return {
            clients: data.clients.map((x) => this.createClient(x)),
            taskTypes: data.taskTypes,
        };
    };

    edit = async (model: Client) => {
        const { data } = await this.api.edit({
            ...model,
            taskTypeIds: model.taskTypeNames?.map((x) => this.taskNames.get(x)!) ?? [],
        });
        return this.createClient(data);
    };

    add = async (model: Client) => {
        const { data } = await this.api.add({
            ...model,
            taskTypeIds: model.taskTypeNames?.map((x) => this.taskNames.get(x)!) ?? [],
        });
        return this.createClient(data);
    };

    private createClient = (model: ClientApiModel): Client => {
        return { ...model, taskTypeNames: model.taskTypeIds?.map((id) => this.taskIds.get(id)!) ?? [] };
    };
}
