import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { Container, Image, Navbar } from "react-bootstrap";
import { inject } from "react-ioc";
import { Link } from "react-router-dom";
import { default as Logo } from "../../assets/logo-full.svg";
import AppViewStore from "../../stores/app.view.store";
import { LoginMenu } from "../api-authorization/LoginMenu";
import "./nav-menu.scss";

export const DefaultNavLink = (props: { to: string; text: string; className?: string }) => {
    return (
        <Link
            className={classNames(
                "link-item bg-transparent h-100 py-4 px-2h my-0 ml-1 mr-0 text-uppercase text-decoration-none font-weight-bold",
                props.className
            )}
            to={props.to}
        >
            {props.text}
        </Link>
    );
};

const UserNavItems = [
    <DefaultNavLink key="nav_time" to="/time" text="Time" />,
    //<DefaultNavLink to="/fetch-data" text="Fetch data" />,
];

const AdminNavItems = [
    //<DefaultNavLink key="nav_users" to="/users" text="Users" />,
    //<DefaultNavLink key="nav_clients" to="/clients" text="Clients" />,
    //<DefaultNavLink key="nav_client-users" to="/client-users" text="Client Users" />,
    //<DefaultNavLink key="nav_tasks" to="/tasks" text="Tasks" />,
    <DefaultNavLink key="nav_time" to="/time" text="Time" />,
    //<DefaultNavLink key="nav_expense-types" to="/expense-types" text="Expense Types" />,
    <DefaultNavLink key="nav_expenses" to="/expenses" text="Expenses" />,
    <DefaultNavLink key="nav_bills" to="/bills" text="Bills" />,
];

const Icon = (
    <Link to="/" className="cursor-pointer text-decoration-none">
        <Image className="bg-transparent py-1 px-0 m-0" src={Logo} width={150} height={42} />
    </Link>
);

@observer
export class NavMenu extends React.Component {
    @inject private viewStore!: AppViewStore;

    render() {
        const { isAdmin, isLoggedIn } = this.viewStore;
        const items = isLoggedIn ? (isAdmin ? AdminNavItems : UserNavItems).slice() : [];
        items.push(<LoginMenu key="nav_login" />);

        return (
            <header>
                <Navbar className="navbar-expand-sm bg-dark border-0 mb-3 p-0">
                    <Container>
                        {Icon}
                        <ul className="navbar-nav flex-grow">{items}</ul>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
