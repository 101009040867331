import { AxiosResponse } from "axios";
import { inject } from "react-ioc";
import AuthorizedAxios from "../../common/authorized-axios";

class ExpenseTypeApi {
    private controller = "expensetypeapi";
    @inject axios!: AuthorizedAxios;

    get = async (): Promise<AxiosResponse<ExpenseTypeApiModel[]>> => {
        return (await this.axios.createInstance()).get(`${this.controller}/get`);
    };

    add = async (model: EditExpenseTypeApiModel): Promise<AxiosResponse<ExpenseTypeApiModel>> => {
        return (await this.axios.createInstance()).post(`${this.controller}/add`, model, {
            responseType: "json",
        });
    };

    edit = async (model: EditExpenseTypeApiModel): Promise<AxiosResponse<ExpenseTypeApiModel>> => {
        return (await this.axios.createInstance()).post(`${this.controller}/edit`, model, {
            responseType: "json",
        });
    };
}

export interface ExpenseTypeApiModel extends EditExpenseTypeApiModel {}

export interface EditExpenseTypeApiModel {
    id: number;
    name: string;
}

export default ExpenseTypeApi;
