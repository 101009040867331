import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { inject } from "react-ioc";
import AppViewStore from "../../../stores/app.view.store";
import SubscriptionService, { SubscriptionsTypes } from "../../common/subscription-service";
import { User, UserDataStore } from "./user-data.store";

const questions = [
    {
        name: "name",
        type: "text",
        title: "What is their name?",
        placeHolder: "Clara Oswald",
        isRequired: true,
        autoComplete: "name",
    },
    {
        name: "title",
        type: "text",
        title: "What is their title?",
        placeHolder: "Senior VP - Interplanetary Communications",
        isRequired: true,
        autoComplete: "name",
    },
    {
        name: "hireDate",
        title: "When were they hired?",
        isRequired: true,
        inputType: "date",
        type: "text",
    },
    //{
    //    name: "eligibleHours",
    //    type: "text",
    //    title: "How many hours are they eligible for?",
    //    isRequired: true,
    //    inputType: "number",
    //    min: "0",
    //},
    {
        name: "active",
        type: "boolean",
        title: "Are their time and expenses assignable to new bills?",
        isRequired: true,
    },
];

const questionRole = {
    name: "role",
    type: "dropdown",
    title: "What is their administrative permission level?",
    isRequired: true,
    choices: ["None", "User", "Admin"],
};

const questionEmail = {
    name: "email",
    type: "text",
    title: "What is their email address?",
    placeHolder: "coswald@tardis.com",
    isRequired: true,
};

const defaultJson = {
    showQuestionNumbers: "off",
    completedHtml: "<p>Updating user...</p>",
};

export class UserViewStore {
    @inject private dataStore!: UserDataStore;
    @inject private subscriptionService!: SubscriptionService;
    @inject private appStore!: AppViewStore;

    isInitializing?: boolean;
    items: User[] = [];
    editingItem?: User;
    search = "";

    constructor() {
        makeObservable(this, {
            isInitializing: observable,
            items: observable,
            editingItem: observable,
            search: observable,
            init: action,
            startEditItem: action,
            setSearch: action,
            json: computed,
        });
    }

    init = async () => {
        if (this.isInitializing !== undefined) {
            return;
        }

        this.isInitializing = true;
        try {
            const users = await this.dataStore.get();

            runInAction(() => {
                this.items = users;
                this.isInitializing = false;
            });
        } catch (e) {
            runInAction(() => (this.isInitializing = undefined));
            throw e;
        }
    };

    startEditItem = (user?: User) => {
        this.editingItem = user;
    };

    saveItem = async (user: User) => {
        const index = this.items.findIndex((x) => x.id === user.id);

        try {
            const add = user.id === 0;
            const updatedUser = add ? await this.dataStore.add(user) : await this.dataStore.edit(user);

            runInAction(() => {
                if (add) {
                    this.items.push(updatedUser);
                } else {
                    this.items.splice(index, 1, updatedUser);
                }
                this.startEditItem(undefined);
            });
        } catch (e) {
            runInAction(() => {
                this.startEditItem(undefined);
            });
            throw e;
        }

        this.subscriptionService.publish(SubscriptionsTypes.UserList);
    };

    setSearch = (value: string) => {
        this.search = value.toLowerCase();
    };

    startAddItem = () => {
        this.editingItem = {
            id: 0,
            name: "",
            active: true,
            role: "None",
            email: "",
            eligibleHours: 0,
            hasLoginAccount: false,
        };
    };

    get json() {
        const questionSet: any[] = questions.slice(0, 1);

        if (this.editingItem?.email !== this.appStore.email) {
            if (!this.editingItem?.hasLoginAccount) {
                questionSet.push(questionEmail);
            }
            questionSet.push(questionRole);
        }

        questionSet.push(...questions.slice(1));

        return { ...defaultJson, questions: questionSet };
    }
}
