import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { inject } from "react-ioc";
import ClientSelect from "../common/client-select";
import DefaultEditPageHeader from "../common/default-edit-page-header";
import DefaultExportRangeModal from "../common/default-export-range-modal";
import DefaultImportModal from "../common/default-import-modal";
import DefaultLoader from "../common/default-loader";
import { DollarFormatter } from "../common/formatters";
import "./expense-layout.scss";
import ExpenseSelect from "./expense-select";
import { ExpenseClientSelectViewStore } from "./store/expense-client-select-view.store";
import { ExpenseViewStore } from "./store/expense-view.store";

@observer
export default class ExpenseLayout extends React.Component {
    @inject private viewStore!: ExpenseViewStore;
    @inject private clientSelectStore!: ExpenseClientSelectViewStore;

    componentDidMount() {
        this.viewStore.init();
    }

    componentWillUnmount() {
        this.viewStore.setSearch("");
        this.clientSelectStore.setSearch("");
    }

    render() {
        const { search, selected, setSearch, setSelected } = this.clientSelectStore;
        const {
            isInitializing,
            editingItem,
            getJson,
            clientList,
            startEditItem,
            saveItem,
            showImportModal,
            setShowImportModal,
            importing,
            imported,
            importFile,
            validating,
            validated,
            validateFile,
            showExportModal,
            setShowExportModal,
            expensesForCalendar,
            downloadTemplate,
            downloadInRange,
        } = this.viewStore;

        return (
            <div>
                <DefaultExportRangeModal
                    title="Export Expenses in Date Range"
                    formatValue={(value) => DollarFormatter.format(value)}
                    show={showExportModal}
                    items={expensesForCalendar}
                    onHide={() => setShowExportModal(false)}
                    onExport={(start, end) => downloadInRange(start, end)}
                />
                <DefaultImportModal
                    show={showImportModal}
                    onSetShow={setShowImportModal}
                    title={"Expenses"}
                    importing={importing}
                    imported={imported}
                    validating={validating}
                    validated={validated}
                    onDownloadTemplateClick={downloadTemplate}
                    onImportClick={importFile}
                    onValidateClick={validateFile}
                />
                <DefaultEditPageHeader
                    title="Expense"
                    description="View, edit, and add expenses to a client.  Select a client to get started."
                    json={getJson(selected?.value)}
                    editingItem={editingItem}
                    onStartEdit={startEditItem}
                    onSave={saveItem}
                />
                <div className="d-flex align-content-top text-nowrap">
                    <div
                        className={classNames("m-0 animate-width", {
                            "w-50 pl-0 py-0 pr-2": selected,
                            "w-100 p-0": !selected,
                        })}
                    >
                        <ClientSelect
                            clients={clientList}
                            clientSearch={search}
                            isInitializing={isInitializing}
                            selectedClient={selected}
                            onSetClientSelected={setSelected}
                            onClientSearch={setSearch}
                            onClientImportButtonClick={() => setShowImportModal(true)}
                            onClientExportButtonClick={() => setShowExportModal(true)}
                        />
                    </div>
                    <div
                        className={classNames("m-0 p-0 animate-width w-100", {
                            "hide-panel": !selected,
                        })}
                    >
                        <div
                            style={{
                                minWidth: "10rem",
                            }}
                        >
                            <ExpenseSelect />
                        </div>
                    </div>
                </div>
                {isInitializing ? <DefaultLoader className="mt-4" /> : undefined}
            </div>
        );
    }
}
