import { observer } from "mobx-react";
import React from "react";
import { inject } from "react-ioc";
import DefaultEditPage from "../common/default-edit-page";
import { TaskType } from "./store/tasktype-data.store";
import { TaskTypeViewStore } from "./store/tasktype-view.store";

const TableHeader = (
    <thead className="user-select-none">
        <tr>
            {["Task Description", "Sort Priority"].map((x) => (
                <th key={`tasktype_row-${x}`} className="border-top-0">
                    {x}
                </th>
            ))}
        </tr>
    </thead>
);

const EditableTableCells = (props: { item: TaskType }) => {
    return (
        <React.Fragment>
            <td hidden>{props.item.id}</td>
            <td>{props.item.name}</td>
            <td>{props.item.sortOrder}</td>
        </React.Fragment>
    );
};

const json = {
    elements: [
        {
            name: "name",
            type: "text",
            title: "What is a short description for this task?",
            placeHolder: "Duties of The Doctor",
            isRequired: true,
        },
        {
            name: "sortOrder",
            type: "text",
            inputType: "number",
            title: "What is the bill sort priority for this task?",
            placeHolder: "-",
            min: 0,
            max: 10000,
            isRequired: false,
        },
    ],
    showQuestionNumbers: "off",
    completedHtml: "<p>Updating...</p>",
    completeText: "Save",
};

@observer
export default class TaskTypeLayout extends React.Component {
    @inject private viewStore!: TaskTypeViewStore;

    componentDidMount() {
        this.viewStore.init();
    }

    componentWillUnmount() {
        this.viewStore.setSearch("");
    }

    render() {
        const { isInitializing, items, editingItem, search, startEditItem, startAddItem, saveItem, setSearch } =
            this.viewStore;

        return (
            <DefaultEditPage
                title="Task"
                description="View, edit, and add new tasks."
                json={json}
                editingItem={editingItem}
                onStartEdit={startEditItem}
                onAddButtonClick={startAddItem}
                onSave={saveItem}
                items={items}
                isInitializing={isInitializing}
                search={search}
                onSetSearch={setSearch}
                onMatchesSearch={(x, s) => !s || (x.name + x.sortOrder?.toString()).toLowerCase().includes(s)}
                tableHeader={TableHeader}
                tableCells={EditableTableCells}
            />
        );
    }
}
