import { observer } from "mobx-react";
import React from "react";
import { Button } from "react-bootstrap";
import { ButtonVariant } from "react-bootstrap/esm/types";
import { inject } from "react-ioc";
import { Link } from "react-router-dom";
import AppViewStore from "../stores/app.view.store";
import { ApplicationPaths } from "./api-authorization/ApiAuthorizationConstants";
import authService from "./api-authorization/AuthorizeService";

const ListLink = (props: { children: any; path: string; variant?: ButtonVariant }) => {
    return (
        <div>
            <Link className="ml-2" to={props.path}>
                <Button
                    variant={props.variant ?? "primary-outline"}
                    className="rounded-0 border-3 border-right-0 border-top-0  border-bottom-0 font-weight-bold text-uppercase"
                >
                    {props.children}
                </Button>
            </Link>
        </div>
    );
};

const ExplainContent = (isAdmin: boolean) => (
    <div className="mt-5 d-flex flex-column">
        <h4>Record</h4>
        <ListLink path="/time">Record Time</ListLink>
        {isAdmin ? (
            <React.Fragment>
                <ListLink path="/expenses">Record Expenses</ListLink>
                <h4 className="mt-3">Update</h4>
                <ListLink variant="secondary-outline" path="/users">
                    Update users
                </ListLink>
                <ListLink variant="secondary-outline" path="/clients">
                    Add or edit clients
                </ListLink>
                <ListLink variant="secondary-outline" path="/client-users">
                    Assign users to clients
                </ListLink>
                <ListLink variant="secondary-outline" path="/expense-types">
                    Update expense descriptions
                </ListLink>
                <ListLink variant="secondary-outline" path="/tasks">
                    Update task descriptions
                </ListLink>
                <h4 className="mt-3">Generate</h4>
                <ListLink variant="info-outline" path="/bills">
                    Bill Reports
                </ListLink>
            </React.Fragment>
        ) : undefined}
    </div>
);

const LoginContent = (
    <div>
        <Button
            variant="primary-outline"
            className="rounded-0 border-3 font-weight-bold text-uppercase"
            onClick={() => window.location.replace(ApplicationPaths.IdentityLoginPath)}
        >
            Let's Get Started
        </Button>
    </div>
);

@observer
export class Home extends React.Component {
    @inject private appViewStore!: AppViewStore;
    private authSubscriptionId?: number;

    constructor() {
        super({});
        this.state = { isAuthenticated: undefined };
    }

    componentDidMount() {
        this.authSubscriptionId = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this.authSubscriptionId);
    }

    private async populateState() {
        const isAuthenticated = await authService.isAuthenticated();
        this.setState({
            isAuthenticated,
        });
    }

    render() {
        const { isAuthenticated } = this.state as any;
        const { isAdmin } = this.appViewStore;

        return (
            <div>
                <h1 className="mt-4 mb-2 user-select-none">Home</h1>
                <p className="mb-4 user-select-none">
                    Welcome to ToneyKorf Partners' Time and Billing System! This tool provides a centralized location
                    for recording time for employees of ToneyKorf Partners, and generating bills for their clients.
                </p>
                {isAuthenticated === undefined ? undefined : isAuthenticated ? ExplainContent(isAdmin) : LoginContent}
            </div>
        );
    }
}
