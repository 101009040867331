import React from "react";

export interface DefaultPageHeaderProps {
    description: string;
    isPlural?: boolean;
    title: string;
}

const DefaultPageHeader = (props: DefaultPageHeaderProps) => {
    return (
        <div>
            <h1 className="mt-4 mb-2 user-select-none">
                {props.title}
                {props.isPlural ? "" : "s"}
            </h1>
            <p className="mb-4 user-select-none">{props.description}</p>
        </div>
    );
};

export default DefaultPageHeader;
