import classNames from "classnames";
import React from "react";
import { Button } from "react-bootstrap";

const DefaultSaveButton = (props: { onClick: () => void; mlauto?: boolean; className?: string }) => {
    return (
        <Button
            variant="primary-outline"
            className={classNames("rounded-0", props.className, { "ml-auto": props.mlauto })}
            size="sm"
            onClick={props.onClick}
        >
            Save
        </Button>
    );
};

export default DefaultSaveButton;
