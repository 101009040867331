import { faFileDownload, faFileUpload, faFilter, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { useInstance } from "react-ioc";
import AppViewStore from "../../stores/app.view.store";

export interface SearchBarProps {
    search: string;
    setSearch: (value: string) => void;

    onAddButtonClick?: () => void;
    onImportButtonClick?: () => void;
    onExportButtonClick?: () => void;

    filterProps?: {
        exists: boolean;
        onButtonClick: () => void;
    };

    fullWidth?: boolean;
    className?: string;
}

const SearchBar = (props: SearchBarProps) => {
    const { isAdmin } = useInstance(AppViewStore);
    return (
        <div
            className={classNames("ml-auto p-0", props.className, {
                "w-50": !props.fullWidth,
                "w-100": props.fullWidth,
            })}
        >
            <div className="mb-1 d-flex justify-content-end">
                <InputGroup
                    className="bg-transparent border-bottom"
                    onChange={(event: any) => props.setSearch(event.target.value)}
                >
                    <InputGroup.Text className="bg-transparent border-0">
                        {" "}
                        <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <FormControl
                        placeholder="Search"
                        aria-label="Search"
                        size="sm"
                        className={"border-0 bg-transparent shadow-none"}
                    />
                </InputGroup>
                {props.onAddButtonClick !== undefined ? (
                    <Button
                        variant="secondary-outline"
                        className="rounded-0 ml-3"
                        size="sm"
                        onClick={props.onAddButtonClick}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                ) : undefined}
                {isAdmin && props.filterProps !== undefined ? (
                    <Button
                        variant={props.filterProps.exists ? "primary-outline" : "secondary-outline"}
                        className="rounded-0 ml-3"
                        size="sm"
                        onClick={props.filterProps.onButtonClick}
                    >
                        <FontAwesomeIcon icon={faFilter} />
                    </Button>
                ) : undefined}
                {isAdmin && props.onExportButtonClick !== undefined ? (
                    <Button
                        variant="secondary-outline"
                        className="rounded-0 ml-3"
                        size="sm"
                        onClick={props.onExportButtonClick}
                    >
                        <FontAwesomeIcon icon={faFileDownload} className="mr-1" /> Export Range
                    </Button>
                ) : undefined}
                {isAdmin && props.onImportButtonClick !== undefined ? (
                    <Button
                        variant="secondary-outline"
                        className="rounded-0 ml-3"
                        size="sm"
                        onClick={props.onImportButtonClick}
                    >
                        <FontAwesomeIcon icon={faFileUpload} className="mr-1" /> Upload New Items
                    </Button>
                ) : undefined}
            </div>
        </div>
    );
};

export default SearchBar;
