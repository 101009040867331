import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import React from "react";
import { Alert, Button, Table } from "react-bootstrap";
import { useInstance } from "react-ioc";
import DefaultLoader from "../../common/default-loader";
import DefaultSaveButton from "../../common/default-save-button";
import EditItem from "../../common/edit-item";
import { DollarFormatter } from "../../common/formatters";
import { CalculationType } from "../api/bill.api";
import "../bill-layout.scss";
import { Bill, Discount } from "../store/bill-data.store";
import { BillDiscountSelectViewStore } from "../store/bill-discount-select-view.store";
import { AccordionItem } from "../store/bill-view.store";
import BillAccordionItem from "./bill-accordion-item";

const TableHeader = (
    <thead className="user-select-none">
        <tr>
            <th className="border-top-0 header-date">Discount Name</th>
            <th className="border-top-0">Amount</th>
        </tr>
    </thead>
);

const EditableTableCells = (props: { item: Discount }) => {
    return (
        <React.Fragment>
            <td hidden>{props.item.id}</td>
            <td>{props.item.name}</td>
            <td>
                {props.item === undefined || props.item.calculationType === 0
                    ? DollarFormatter.format(props.item?.amount ?? 0)
                    : `${props.item?.amount ?? 0}%`}
            </td>
        </React.Fragment>
    );
};

interface DiscountSelectProps {
    discounts: Discount[];
    onStartEdit: (item: Discount) => void;
}

const DiscountSelect = observer((props: DiscountSelectProps) => {
    return (
        <React.Fragment>
            <Table borderless hover striped>
                {TableHeader}
                <tbody>
                    {props.discounts.map((item) => (
                        <tr onClick={() => props.onStartEdit(item)}>
                            <EditableTableCells item={item} />
                        </tr>
                    ))}
                </tbody>
            </Table>
        </React.Fragment>
    );
});

interface BillAccordionItemDiscountSelectProps {
    isInitializing?: boolean;

    selectedItem: AccordionItem;
    setSelectedItem: (item: AccordionItem) => void;

    selectedBill: Bill;

    onSave: () => void;
}

const BillAccordionItemDiscountSelect = observer((props: BillAccordionItemDiscountSelectProps) => {
    const { json, discounts, saveItem, startAddItem, startEditItem, editingItem } =
        useInstance(BillDiscountSelectViewStore);
    const showSummary = ["none", "bill-expenses", "bill-time"].includes(props.selectedItem);

    return (
        <React.Fragment>
            <EditItem
                json={json}
                title="Discount"
                editingItem={editingItem}
                onStartEdit={startEditItem}
                onSave={saveItem}
            />
            <BillAccordionItem
                className="mt-3"
                title="Discount Selection"
                description="Please include all related billing discounts. Below is the list of discounts already applied to this bill. Click to edit a discount entry, or add new discounts as needed."
                itemType="bill-discounts"
                selectedItemType={props.selectedItem}
                showEdit={!props.selectedBill?.locked && showSummary}
                showSummary={showSummary}
                onEdit={props.setSelectedItem}
                headerSummary={
                    <div className="w-100 d-flex mt-2">
                        <div className="w-25">
                            <p className="mb-1 text-subtle">Number of Discounts</p>
                            <p>{discounts?.length ?? 0}</p>
                        </div>
                        <div className="w-25">
                            <p className="mb-1 text-subtle">Discounted Amount</p>
                            <p className="mb-1">
                                {DollarFormatter.format(
                                    discounts
                                        ?.filter((x) => x.calculationType === 0)
                                        .reduce((a, b) => a + b.amount, 0) ?? 0
                                )}
                            </p>
                            {(discounts?.filter((x) => x.calculationType !== 0).length ?? 0) > 0
                                ? discounts
                                      ?.filter((x) => x.calculationType !== 0)
                                      .map((x) => {
                                          return (
                                              <p className="mb-1">{`${CalculationType[x.calculationType]} at ${
                                                  x.amount
                                              }%`}</p>
                                          );
                                      })
                                : undefined}
                        </div>
                        <div className="w-50">
                            <p className="mb-1 text-subtle">Discount List</p>
                            {discounts?.length ? (
                                discounts
                                    ?.map((x) => x.name)
                                    .filter((v, i, a) => a.indexOf(v) === i)
                                    .map((x) => {
                                        return <p className="mb-1">{x}</p>;
                                    })
                            ) : (
                                <p className="mb-1">None</p>
                            )}
                        </div>
                    </div>
                }
                body={
                    <div className="w-100 d-flex flex-column">
                        {discounts?.length ? (
                            <DiscountSelect
                                discounts={props.isInitializing ? [] : discounts}
                                onStartEdit={startEditItem}
                            />
                        ) : (
                            <Alert variant="info">
                                <p className="m-0">No discounts are currently applied this bill.</p>
                            </Alert>
                        )}
                        {props.isInitializing ? <DefaultLoader className="mt-4" /> : undefined}
                        <div className="w-100 d-flex">
                            <Button
                                variant="secondary-outline"
                                className="rounded-0 ml-auto mr-2"
                                size="sm"
                                onClick={() => startAddItem(props.selectedBill)}
                            >
                                <FontAwesomeIcon icon={faPlus} className="mr-1" /> Add Discount
                            </Button>
                            <DefaultSaveButton onClick={props.onSave} />
                        </div>
                    </div>
                }
            />
        </React.Fragment>
    );
});

export default BillAccordionItemDiscountSelect;
