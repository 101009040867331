import { action, makeObservable, observable, runInAction } from "mobx";
import * as Survey from "survey-react";
import authService from "../components/api-authorization/AuthorizeService";

class AppViewStore {
    private authSubscriptionId?: number;
    isAdmin = false;
    email?: string;
    isLoggedIn = false;
    isInitializing = true;

    constructor() {
        makeObservable(this, {
            isInitializing: observable,
            isAdmin: observable,
            isLoggedIn: observable,
            init: action,
        });
    }

    init = async () => {
        this.isInitializing = true;
        this.authSubscriptionId = authService.subscribe(() => this.populateState());
        await this.populateState();

        runInAction(() => (this.isInitializing = false));
    };

    dispose = () => {
        this.authSubscriptionId && authService.unsubscribe(this.authSubscriptionId);
    };

    private populateState = async () => {
        const [token, isAuthenticated, isAdmin, user] = await Promise.all([
            authService.getAccessToken(),
            authService.isAuthenticated(),
            authService.isAdmin(),
            authService.getUser(),
        ]);
        runInAction(() => {
            this.isLoggedIn = isAuthenticated;
            this.isAdmin = isAdmin;
            this.email = user?.name;
        });

        Survey.ChoicesRestfull.onBeforeSendRequest = function (_: any, options: any) {
            options.request.setRequestHeader("Authorization", "Bearer " + token);
        };
    };
}

export default AppViewStore;
