import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { inject } from "react-ioc";
import { DropdownChoice } from "../../common/api-utils";
import { Bill, BillDataStore, Discount } from "./bill-data.store";
import { BillSelectViewStore } from "./bill-select-view.store";

const GenerateJson = (dynamicDiscounts: DropdownChoice[]) => {
    return {
        elements: [
            {
                name: "isCalculated",
                type: "boolean",
                title: "Is this a generic discount?",
                isRequired: true,
            },
            {
                name: "name",
                type: "text",
                title: "What is the name of this discount?",
                placeHolder: "Travel Discount at 50%",
            },
            {
                name: "amount",
                inputMask: "currency",
                type: "text",
                title: "How much will this discount be for?",
                requiredIf: "{isCalculated} = false",
                visibleIf: "{isCalculated} = false",
            },
            {
                name: "applyLast",
                type: "boolean",
                title: "Should this discount be applied last?",
                requiredIf: "{isCalculated} = false",
                visibleIf: "{isCalculated} = false",
            },
            {
                name: "calculationType",
                type: "dropdown",
                choices: dynamicDiscounts,
                showOptionsCaption: false,
                title: "Which type of generic discount?",
                requiredIf: "{isCalculated} = true",
                visibleIf: "{isCalculated} = true",
            },
            {
                name: "percentage",
                type: "text",
                inputType: "number",
                min: 1,
                max: 100,
                title: "What percentage should be applied for this discount type?",
                requiredIf: "{isCalculated} = true",
                visibleIf: "{isCalculated} = true",
            },
        ],
        showQuestionNumbers: "off",
        completedHtml: "<p>Updating...</p>",
        completeText: "Save",
    };
};

export class BillDiscountSelectViewStore {
    @inject private dataStore!: BillDataStore;
    @inject private selectStore!: BillSelectViewStore;

    isInitializing?: boolean;
    editingItem?: Discount & { isCalculated: boolean; percentage?: number };

    constructor() {
        makeObservable(this, {
            isInitializing: observable,
            editingItem: observable,
            startEditItem: action,
            startAddItem: action,
            discounts: computed,
            json: computed,
        });
    }

    startEditItem = (discount?: Discount) => {
        this.editingItem = discount
            ? {
                  ...discount,
                  isCalculated: discount?.calculationType !== 0,
                  percentage: discount?.calculationType !== 0 ? discount.amount : undefined,
              }
            : undefined;
    };

    startAddItem = (bill: Bill) => {
        this.editingItem = {
            id: 0,
            billId: bill.id,
            name: "",
            amount: 0,
            calculationType: 0,
            isCalculated: false,
            applyLast: false,
        };
    };

    saveItem = async (discount: Discount & { isCalculated: boolean; percentage?: number }) => {
        if (!this.selectStore.selected) {
            return;
        }

        const index =
            discount.id === 0 ? -1 : this.selectStore.selected.discounts.findIndex((x) => x.id === discount.id);

        try {
            const discountToSave = discount.isCalculated
                ? { ...discount, amount: discount.percentage ?? 0 }
                : { ...discount };
            const savedDiscount = await this.dataStore.updateBillDiscount(discountToSave);

            runInAction(() => {
                if (~index) {
                    this.selectStore.selected?.discounts.splice(index, 1, savedDiscount);
                } else {
                    this.selectStore.selected?.discounts.push(savedDiscount);
                }
                this.startEditItem(undefined);
            });
        } catch (e) {
            runInAction(() => {
                this.startEditItem(undefined);
            });
            throw e;
        }
    };

    get discounts() {
        return this.selectStore.selected?.discounts;
    }

    get json() {
        return GenerateJson(this.dataStore.discountTypeOptions);
    }
}
