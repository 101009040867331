import React, { useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import DefaultLoader from "./default-loader";

interface Props {
    title: string;

    show: boolean;
    onSetShow: (value: boolean) => void;

    importing: boolean;
    imported: boolean;
    validating: boolean;
    validated: boolean;
    importBtnText?: string;
    importedBtnText?: string;
    onDownloadTemplateClick?: () => void;
    onImportClick: (file: any) => Promise<string[]>;
    onValidateClick: (file: any) => Promise<string[]>;
}

const DefaultImportModal = (props: Props) => {
    const [file, setFile] = useState<any>();
    const [errors, setErrors] = useState<string[]>([]);
    const [successMessages, setSuccessMessages] = useState<string[]>([]);

    const handleErrorResult = async (onClick: (file: any) => Promise<string[]>) => {
        const errors = await onClick(file);
        setErrors(errors);
        if (!errors.length) {
            setSuccessMessages(successMessages.concat(["Success!"]));
        }
    };

    const reset = () => {
        setFile(undefined);
        setErrors([]);
        setSuccessMessages([]);
    };

    const hide = () => {
        props.onSetShow(false);
        reset();
    };

    return (
        <React.Fragment>
            <Modal show={props.show} onHide={hide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Import - {props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!!successMessages.length &&
                        successMessages.map((x) => (
                            <Alert
                                dismissible
                                variant="success"
                                onClose={() => setSuccessMessages(successMessages.slice(1))}
                            >
                                {x}
                            </Alert>
                        ))}
                    {!!errors.length && (
                        <Alert dismissible variant="danger" onClose={() => setErrors([])}>
                            <ul className="m-0">
                                {errors.map((x) => (
                                    <li>{x}</li>
                                ))}
                            </ul>
                        </Alert>
                    )}
                    <p>
                        This section will allow an Excel import for {props.title.toLowerCase()}. Before importing,
                        please double check the following:
                    </p>
                    <ul>
                        <li>The file only has only one sheet</li>
                        <li>The headers are all valid for this import</li>
                        <li>Columns with number fields only contain numbers</li>
                        <li>Date columns are formatted as Month/Day/Year</li>
                    </ul>
                    <Form.Group controlId="formFileSm" className="mb-3">
                        <Form.Label>{props.title} Excel file</Form.Label>
                        <Form.Control
                            disabled={props.validating || props.validated || props.importing || props.imported}
                            type="file"
                            size="sm"
                            onChange={(event) => {
                                setFile((event.target as any)?.files?.[0]);
                            }}
                            accept=".xlsx,.xls"
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {props.onDownloadTemplateClick ? (
                        <Button variant="secondary-outline" onClick={props.onDownloadTemplateClick}>
                            Download Template
                        </Button>
                    ) : undefined}
                    <Button
                        disabled={props.validating || props.validated || !file}
                        variant="primary-outline"
                        onClick={async () => handleErrorResult(props.onValidateClick)}
                    >
                        {props.validating ? (
                            <DefaultLoader small variant="primary" className="p-0 m-0" />
                        ) : props.validated ? (
                            "Validated"
                        ) : (
                            "Validate"
                        )}
                    </Button>
                    <Button
                        disabled={!props.validated || props.importing || props.imported}
                        variant="primary-outline"
                        onClick={() => handleErrorResult(props.onImportClick)}
                    >
                        {props.importing ? (
                            <DefaultLoader small variant="primary" className="p-0 m-0" />
                        ) : props.imported ? (
                            props.importedBtnText ?? "Uploaded New " + props.title
                        ) : (
                            props.importBtnText ?? "Upload New " + props.title
                        )}
                    </Button>
                    <Button variant="secondary-outline" onClick={hide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default DefaultImportModal;
