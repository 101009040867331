import { AxiosResponse } from "axios";
import { inject } from "react-ioc";
import {
    ClientUserBasicApiModel,
    DateRangeApiModel,
    DownloadFileFromResponse,
    FileApiModel,
    NamedIdApiModel,
} from "../../common/api-utils";
import AuthorizedAxios from "../../common/authorized-axios";

class TimeEntryApi {
    private controller = "timeentryapi";
    @inject axios!: AuthorizedAxios;

    get = async (): Promise<AxiosResponse<TimeEntryFormApiModel>> => {
        return (await this.axios.createInstance()).get(`${this.controller}/get`);
    };

    add = async (model: EditTimeEntryApiModel): Promise<AxiosResponse<TimeEntryApiModel>> => {
        return (await this.axios.createInstance()).post(`${this.controller}/add`, model, {
            responseType: "json",
        });
    };

    edit = async (model: EditTimeEntryApiModel): Promise<AxiosResponse<TimeEntryApiModel>> => {
        return (await this.axios.createInstance()).post(`${this.controller}/edit`, model, {
            responseType: "json",
        });
    };

    import = async (model: FileApiModel): Promise<AxiosResponse<string[]>> => {
        const form = new FormData();
        form.append("fileName", model.fileName);
        form.append("formFile", model.formFile);

        return (await this.axios.createInstance()).post(`${this.controller}/import`, form, {
            responseType: "json",
        });
    };

    downloadImportTemplate = async (): Promise<void> => {
        const response = await (
            await this.axios.createInstance()
        ).get<any>(`${this.controller}/downloadImportTemplate`, {
            responseType: "blob",
        });

        DownloadFileFromResponse(response);
    };

    downloadInRange = async (model: DateRangeApiModel): Promise<void> => {
        const response = await (
            await this.axios.createInstance()
        ).post<any>(`${this.controller}/downloadInRange`, model, {
            responseType: "blob",
        });

        DownloadFileFromResponse(response);
    };

    validate = async (model: FileApiModel): Promise<AxiosResponse<string[]>> => {
        const form = new FormData();
        form.append("fileName", model.fileName);
        form.append("formFile", model.formFile);

        return (await this.axios.createInstance()).post(`${this.controller}/validate`, form, {
            responseType: "json",
        });
    };
}

interface TimeEntryFormApiModel {
    timeEntries: TimeEntryApiModel[];

    clientUsers: ClientUserBasicApiModel[];
    locations: NamedIdApiModel[];
    formOptions: TimeEntryFormClientOptionModel[];
}

export interface TimeEntryApiModel extends EditTimeEntryApiModel {}

export interface EditTimeEntryApiModel {
    id: number;
    clientUserId: number;
    taskTypeId: number;

    hours: number;
    date: Date;
    narrative: string;

    location?: number;
}

interface TimeEntryFormClientOptionModel extends NamedIdApiModel {
    taskTypeOptions: NamedIdApiModel[];
    clientUserOptions: NamedIdApiModel[];
}

export default TimeEntryApi;
