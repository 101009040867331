import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { Table } from "react-bootstrap";
import { useInstance } from "react-ioc";
import SearchBar from "../../common/search-bar";
import { Bill } from "../store/bill-data.store";
import { BillSelectViewStore } from "../store/bill-select-view.store";
import { BillViewStore } from "../store/bill-view.store";

const TableHeader = (
    <thead className="user-select-none">
        <tr>
            {[<FontAwesomeIcon icon={faLock} />, "Bill Date", "Coverage Start Date", "Coverage End Date"].map((x) => (
                <th key={`bill-header_${x}`} className="border-top-0">
                    {x}
                </th>
            ))}
        </tr>
    </thead>
);

const EditableTableCells = (props: { item: Bill }) => {
    return (
        <React.Fragment>
            <td hidden>{props.item.id}</td>
            <td className="text-center">{props.item.locked ? <FontAwesomeIcon icon={faLock} /> : undefined}</td>
            <td>{props.item.billedDate}</td>
            <td>{props.item.coverageStartDate}</td>
            <td>{props.item.coverageEndDate}</td>
        </React.Fragment>
    );
};

const MatchesSearch = (bill: Bill, search: string) => {
    return (
        !search || `${bill.billedDate}${bill.coverageStartDate}${bill.coverageEndDate}`.toLowerCase().includes(search)
    );
};

const SortBills = (a: Bill, b: Bill) =>
    (a.billedDate ?? "") > (b.billedDate ?? "") ? -1 : a.billedDate === b.billedDate ? 0 : 1;

const BillSelect = observer(() => {
    const { isInitializing, items, createBill } = useInstance(BillViewStore);
    const { search, setSearch, selected, setSelected } = useInstance(BillSelectViewStore);

    return (
        <React.Fragment>
            <SearchBar
                fullWidth
                className="animate-width"
                search={search}
                setSearch={setSearch}
                onAddButtonClick={createBill}
            />
            <Table borderless hover striped className="user-select-none">
                {TableHeader}
                {!isInitializing ? (
                    <tbody className="cursor-pointer">
                        {items
                            .sort(SortBills)
                            .filter((x) => MatchesSearch(x, search))
                            .map((item) => (
                                <tr
                                    onClick={() => {
                                        setSelected(item);
                                    }}
                                    className={classNames({ "bg-primary": item.id === selected?.id })}
                                >
                                    <EditableTableCells item={item} />
                                </tr>
                            ))}
                    </tbody>
                ) : undefined}
            </Table>
        </React.Fragment>
    );
});

export default BillSelect;
