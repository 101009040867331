import { action, computed, makeObservable, observable } from "mobx";
import { inject } from "react-ioc";
import { Bill } from "./bill-data.store";
import { BillViewStore } from "./bill-view.store";

export class BillSelectViewStore {
    @inject private viewStore!: BillViewStore;

    selectedId?: number;
    search = "";

    constructor() {
        makeObservable(this, {
            selectedId: observable,
            search: observable,
            setSearch: action,
            setSelected: action,
            selected: computed,
        });
    }

    setSearch = (value: string) => {
        this.search = value.toLowerCase();
    };

    setSelected = (value?: Bill) => {
        this.selectedId = value?.id === this.selectedId ? undefined : value?.id;
        if (this.selectedId !== undefined) {
            this.viewStore.setAccordionItem("bill-details");
        }
    };

    get selected() {
        return this.viewStore.items.find((x) => x.id === this.selectedId);
    }
}
