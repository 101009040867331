import { action, makeObservable, observable, runInAction } from "mobx";
import { inject } from "react-ioc";
import SubscriptionService, { SubscriptionsTypes } from "../../common/subscription-service";
import { ExpenseType, ExpenseTypeDataStore } from "./expense-type-data.store";

export class ExpenseTypeViewStore {
    @inject private dataStore!: ExpenseTypeDataStore;
    @inject private subscriptionService!: SubscriptionService;

    isInitializing?: boolean;
    items: ExpenseType[] = [];
    editingItem?: ExpenseType;
    search = "";
    private isCreating = false;

    constructor() {
        makeObservable(this, {
            isInitializing: observable,
            items: observable,
            editingItem: observable,
            search: observable,
            init: action,
            startEditItem: action,
            startAddItem: action,
            setSearch: action,
        });
    }

    init = async () => {
        if (this.isInitializing !== undefined) {
            return;
        }

        this.isInitializing = true;

        try {
            const data = await this.dataStore.get();

            runInAction(() => {
                this.items = data;
                this.isInitializing = false;
            });
        } catch (e) {
            runInAction(() => (this.isInitializing = undefined));
            throw e;
        }
    };

    startEditItem = (expenseType?: ExpenseType) => {
        this.editingItem = expenseType;
        this.isCreating = false;
    };

    startAddItem = () => {
        this.editingItem = { id: 0, name: "" };
        this.isCreating = true;
    };

    saveItem = async (expenseType: ExpenseType) => {
        const index = this.items.findIndex((x) => x.id === expenseType.id);

        try {
            const updated = this.isCreating
                ? await this.dataStore.add(expenseType)
                : await this.dataStore.edit(expenseType);

            runInAction(() => {
                if (~index) {
                    this.items.splice(index, 1, updated);
                } else {
                    this.items.push(updated);
                }
                this.startEditItem(undefined);
            });
        } catch (e) {
            runInAction(() => {
                this.startEditItem(undefined);
            });
            throw e;
        }

        this.subscriptionService.publish(SubscriptionsTypes.ExpenseTypeList);
    };

    setSearch = (value: string) => {
        this.search = value.toLowerCase();
    };
}
