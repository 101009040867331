import { faFileDownload, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { Alert, Button, Table } from "react-bootstrap";
import { useInstance } from "react-ioc";
import DefaultLoader from "../../common/default-loader";
import DefaultSaveButton from "../../common/default-save-button";
import { HourFormatter } from "../../common/formatters";
import SearchBar, { SearchBarProps } from "../../common/search-bar";
import "../bill-layout.scss";
import { Bill, Time } from "../store/bill-data.store";
import { BillTimeImportViewStore } from "../store/bill-time-import-view.store";
import { BillTimeSelectViewStore } from "../store/bill-time-select-view.store";
import { AccordionItem } from "../store/bill-view.store";
import BillAccordionItem from "./bill-accordion-item";

interface TimeSelectProps extends SearchBarProps {
    maxTableHeight?: number;

    timeEntries: Time[];
    matchesSearch: (time: Time) => boolean;
}

const TableHeader = (
    <thead className="user-select-none">
        <tr>
            <th className="border-top-0 header-name">Name</th>
            <th className="border-top-0 header-date">Date</th>
            <th className="border-top-0">Hours</th>
            <th className="border-top-0">Description</th>
        </tr>
    </thead>
);

const EditableTableCells = (props: { item: Time }) => {
    return (
        <React.Fragment>
            <td hidden>{props.item.id}</td>
            <td>{props.item.userName}</td>
            <td>{props.item.date}</td>
            <td>{props.item.hours}</td>
            <td>{props.item.narrative}</td>
        </React.Fragment>
    );
};

const TimeSelect = observer((props: TimeSelectProps) => {
    return (
        <React.Fragment>
            <SearchBar fullWidth search={props.search} setSearch={props.setSearch} />
            <Table
                borderless
                hover
                striped
                className={classNames("bill-table d-block user-select-none", {
                    "overflow-y-scroll": !!props.maxTableHeight,
                })}
                style={{
                    maxHeight: props.maxTableHeight,
                }}
            >
                {TableHeader}
                <tbody>
                    {props.timeEntries
                        .filter((x) => props.matchesSearch(x))
                        .map((item) => (
                            <tr>
                                <EditableTableCells item={item} />
                            </tr>
                        ))}
                </tbody>
            </Table>
        </React.Fragment>
    );
});

interface BillAccordionItemTimeSelectProps {
    isInitializing?: boolean;
    maxTableHeight?: number;

    selectedItem: AccordionItem;
    setSelectedItem: (item: AccordionItem) => void;

    selectedBill: Bill;

    timeEntries: Time[];

    onSave: () => void;
}

const BillAccordionItemTimeSelect = observer((props: BillAccordionItemTimeSelectProps) => {
    const { setShowImportModal, exportTimeFile } = useInstance(BillTimeImportViewStore);
    const { search, setSearch, matchesSearch } = useInstance(BillTimeSelectViewStore);

    return (
        <BillAccordionItem
            className="mt-3"
            title="Time Selection"
            description="Please export the list of time entries, update them in Excel, then re-import. This exported list will include all time entries not assigned to any bill, as well as all time entries already assigned to this bill. Below is the list of time entries this bill already contains:"
            itemType="bill-time"
            selectedItemType={props.selectedItem}
            showEdit={
                !props.selectedBill?.locked && (props.selectedItem === "bill-expenses" || props.selectedItem === "none")
            }
            showSummary={props.selectedItem === "none" || props.selectedItem === "bill-expenses"}
            onEdit={props.setSelectedItem}
            headerSummary={
                <div className="w-100 d-flex mt-2">
                    <div className="w-25">
                        <p className="mb-1 text-subtle">Time Entries</p>
                        <p>{props.timeEntries?.length ?? 0}</p>
                    </div>
                    <div className="w-25">
                        <p className="mb-1 text-subtle">Total Hours</p>
                        <p>{HourFormatter.format(props.timeEntries?.reduce((a, b) => a + b.hours, 0) ?? 0)}</p>
                    </div>
                    <div className="w-25">
                        <p className="mb-1 text-subtle">Users</p>
                        <div className="d-flex flex-column">
                            {props.timeEntries
                                ?.map((x) => x.userName)
                                .filter((v, i, a) => a.indexOf(v) === i)
                                .map((x) => {
                                    return <p className="mb-1">{x}</p>;
                                })}
                        </div>
                    </div>
                    <div className="w-25">
                        <p className="mb-1 text-subtle">Task Types</p>
                        <div className="d-flex flex-column">
                            {props.timeEntries
                                ?.map((x) => x.taskTypeName)
                                .filter((v, i, a) => a.indexOf(v) === i)
                                .map((x) => {
                                    return <p className="mb-1">{x}</p>;
                                })}
                        </div>
                    </div>
                </div>
            }
            body={
                <div className="w-100 d-flex flex-column">
                    {props.timeEntries?.length ? (
                        <TimeSelect
                            search={search}
                            setSearch={setSearch}
                            matchesSearch={matchesSearch}
                            maxTableHeight={props.maxTableHeight}
                            timeEntries={props.isInitializing ? [] : props.timeEntries}
                        />
                    ) : (
                        <Alert variant="info">
                            <p className="m-0">No time is currently assigned to this bill.</p>
                        </Alert>
                    )}
                    {props.isInitializing ? <DefaultLoader className="mt-4" /> : undefined}
                    <div className="w-100 d-flex">
                        <Button
                            variant="secondary-outline"
                            className="rounded-0 ml-auto"
                            size="sm"
                            onClick={() => exportTimeFile(props.selectedBill)}
                        >
                            <FontAwesomeIcon icon={faFileDownload} className="mr-1" /> Export
                        </Button>
                        <Button
                            variant="secondary-outline"
                            className="rounded-0 mx-2"
                            size="sm"
                            onClick={() => setShowImportModal(true)}
                        >
                            <FontAwesomeIcon icon={faFileUpload} className="mr-1" /> Import
                        </Button>
                        <DefaultSaveButton onClick={props.onSave} />
                    </div>
                </div>
            }
        />
    );
});

export default BillAccordionItemTimeSelect;
