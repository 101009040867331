import React, { useState } from "react";
import { Button, Modal, Offcanvas } from "react-bootstrap";
import { Survey, SurveyModel } from "survey-react";

export interface EditItemProps<TItem> {
    title: string;
    json: object;
    editingItem?: TItem;

    onStartEdit: (item?: TItem) => void;
    onSave: (item: TItem) => void;
    onValueChanging?: (question: string, value: any, data: any) => any;
}

const areUnsavedChanged = (item: any, newData: any) => {
    return newData ? Object.keys(newData).find((key) => item?.[key] !== newData[key]) !== undefined : false;
};

const EditItem = <TItem extends { id: number | string; name?: string }>(props: EditItemProps<TItem>) => {
    const title =
        props.editingItem?.id !== 0
            ? props.editingItem?.name
                ? `Edit ${props.title} - ${props.editingItem?.name ?? ""}`
                : `Edit ${props.title}`
            : `Add ${props.title}`;

    const [showConfirmClose, setShowConfirmClose] = useState<boolean>(false);
    const [surveyData, setSurveyData] = useState({});

    const data = { ...props.editingItem, ...surveyData };

    return (
        <React.Fragment>
            <Modal show={showConfirmClose} onHide={() => setShowConfirmClose(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Discard all changes?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>Are you sure you want to close this side panel?</div>
                    <span>Closing this panel will revert any changes you've made.</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary-outline" onClick={() => setShowConfirmClose(false)}>
                        Keep Changes
                    </Button>
                    <Button
                        variant="secondary-outline"
                        onClick={() => {
                            setSurveyData({});
                            setShowConfirmClose(false);
                            props.onStartEdit();
                        }}
                    >
                        Discard Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            <Offcanvas
                show={!!props.editingItem}
                onHide={() =>
                    areUnsavedChanged(props.editingItem, surveyData) ? setShowConfirmClose(true) : props.onStartEdit()
                }
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="p-0">
                    {props.editingItem && (
                        <Survey
                            json={props.json}
                            data={{ ...data }}
                            onComplete={(result: SurveyModel) => {
                                props.onSave(result.data);
                                setSurveyData({});
                            }}
                            onValueChanging={(_: any, options: { name: string; value: any }) => {
                                if (options.name === undefined) {
                                    return;
                                }
                                const newData = props.onValueChanging?.(options.name, options.value, surveyData);

                                setSurveyData({ ...surveyData, [options.name]: options.value, ...newData });
                            }}
                        />
                    )}
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    );
};

export default EditItem;
