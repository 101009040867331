import { AxiosResponse } from "axios";
import { saveAs } from "file-saver";

export interface DateRangeApiModel {
    rangeStart: Date;
    rangeEnd: Date;
}

export interface NamedIdApiModel {
    id: number | string;
    name: string;
}

export interface ClientUserBasicApiModel {
    id: number;
    name: string;
    clientId: number;
    clientName: string;
    userId: number;
}

export type DropdownChoice = {
    value: string | number;
    text: string;
};

export type ClientUser = DropdownChoice & { clientId: number; clientName: string };

export interface FileApiModel {
    fileName: string;
    formFile: any;
}

export const CreateDropdownChoice = (item: NamedIdApiModel) => {
    return {
        value: item.id,
        text: item.name,
    };
};

export const SortByName = <T extends { name: string }>(items: T[]) =>
    items.sort((a, b) => (a.name > b.name ? 1 : a.name === b.name ? 0 : -1));

export const SortChoices = <T extends DropdownChoice>(choices: T[]) =>
    choices.sort((a, b) => (a.text === b.text ? 0 : a.text > b.text ? 1 : -1));

export const CreateDropdownChoices = (items: NamedIdApiModel[]) =>
    SortChoices(items.map((x) => CreateDropdownChoice(x)));

export const ConvertFromApiDate = (apiDate?: Date) => apiDate?.toString().split("T")[0];

export const ConvertToDate = (date?: string) => (date ? new Date(date) : undefined);

export const GroupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
        const group = getKey(currentItem);
        if (!previous.has(group)) {
            previous.set(group, [currentItem]);
        } else {
            previous.get(group)!.push(currentItem);
        }

        return previous;
    }, new Map<K, T[]>());

export const GetToday = (value?: Date): { month: number; day: number; year: number } => {
    const date = value ?? new Date();
    const [month, day, year] = [date.getMonth() + 1, date.getDate(), date.getFullYear()];
    return { month, day, year };
};

export const GetDateString = (value: Date) => {
    const { month, day, year } = GetToday(value);
    return `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}`;
};

export const DownloadFileFromResponse = (response: AxiosResponse<any>) => {
    const filename = response.headers["content-disposition"]
        .split(";")
        .find((n: any) => n.includes("filename="))
        .replace("filename=", "")
        .replaceAll('"', "")
        .trim();

    const url = window.URL.createObjectURL(new Blob([response.data]));

    saveAs(url, filename);
};

export const Distinct = <T>(items: T[]) => {
    const arr = [];
    const keys: string[] = [];
    for (var i = 0; i < items.length; i++) {
        const hash = JSON.stringify(items[i]);
        if (!keys.includes(hash)) {
            arr.push(items[i]);
            keys.push(hash);
        }
    }
    return arr;
};
