import classNames from "classnames";
import React from "react";
import { Table } from "react-bootstrap";
import SearchBar from "./search-bar";

type Client = { value: number; text: string };

const TableHeader = (
    <thead className="user-select-none">
        <tr>
            {["Client"].map((x) => (
                <th key={`client_header-${x}`} className="border-top-0">
                    {x}
                </th>
            ))}
        </tr>
    </thead>
);

const EditableTableCells = (props: { item: Client }) => {
    return (
        <React.Fragment>
            <td hidden>{props.item.value}</td>
            <td>{props.item.text}</td>
        </React.Fragment>
    );
};

const MatchesSearch = (client: Client, search: string) => {
    return !search || client.text.toLowerCase().includes(search);
};

export interface ClientSelectProps {
    clients: Client[];
    clientSearch: string;
    onSetClientSelected: (item?: Client) => void;
    onClientSearch: (value: string) => void;

    isInitializing?: boolean;
    selectedClient?: Client;
    maxTableHeight?: number;

    onClientExportButtonClick?: () => void;
    onClientImportButtonClick?: () => void;
}

const ClientSelect = (props: ClientSelectProps) => {
    return (
        <React.Fragment>
            <SearchBar
                className="animate-width"
                fullWidth={!!props.selectedClient}
                search={props.clientSearch}
                setSearch={props.onClientSearch}
                onImportButtonClick={props.onClientImportButtonClick}
                onExportButtonClick={props.onClientExportButtonClick}
            />
            <Table borderless hover striped className="user-select-none">
                {TableHeader}
                {!props.isInitializing ? (
                    <tbody
                        className={classNames("cursor-pointer d-block", {
                            "overflow-y-scroll": !!props.maxTableHeight,
                        })}
                        style={{
                            maxHeight: props.maxTableHeight,
                        }}
                    >
                        {props.clients
                            .filter((x) => MatchesSearch(x, props.clientSearch))
                            .map((item) => (
                                <tr
                                    key={`client-select-${item.value}`}
                                    onClick={() => {
                                        props.onSetClientSelected(item);
                                    }}
                                    className={classNames("d-grid", {
                                        "bg-primary": item.value === props.selectedClient?.value,
                                    })}
                                >
                                    <EditableTableCells item={item} />
                                </tr>
                            ))}
                    </tbody>
                ) : undefined}
            </Table>
        </React.Fragment>
    );
};

export default ClientSelect;
