import React from "react";
import DefaultPageHeader from "./default-page-header";
import EditItem, { EditItemProps } from "./edit-item";

export interface DefaultEditPageHeaderProps<TItem> extends EditItemProps<TItem> {
    description: string;
    isPlural?: boolean;
}

const DefaultEditPageHeader = <TItem extends { id: number | string; name?: string }>(
    props: DefaultEditPageHeaderProps<TItem>
) => {
    return (
        <div>
            <EditItem {...props} />
            <DefaultPageHeader {...props} />
        </div>
    );
};

export default DefaultEditPageHeader;
