import axios from "axios";
import { inject } from "react-ioc";
import { ApplicationPaths } from "../api-authorization/ApiAuthorizationConstants";
import authService from "../api-authorization/AuthorizeService";
import AppToastsViewStore from "./app-toasts/store/app-toasts-view.store";

const GetValidationStrings = (errors: any) => {
    const keys = Object.keys(errors) as string[];
    try {
        const val = keys?.flatMap((key) => (errors[key] as string[]).map((err) => `${key}: ${err}`));
        return !!val?.length ? val : undefined;
    } catch {}
    return undefined;
};

const GetExceptionString = (message: string) => {
    try {
        const val = message.split("\r")?.[0]?.split("Exception: ")?.at(-1)?.trim();
        return !!val ? [val] : undefined;
    } catch {}
    return undefined;
};

export default class AuthorizedAxios {
    @inject private appToastsViewStore!: AppToastsViewStore;

    createInstance = async () => {
        const token = await authService.getAccessToken();
        const api = axios.create({
            baseURL: "api",
            headers: !token ? {} : { Authorization: `Bearer ${token}` },
        });

        api.interceptors.response.use(
            (response) => response,
            (error) => {
                if (401 === error.response.status) {
                    window.location.href = ApplicationPaths.IdentityLoginPath;
                } else {
                    const toastErrors: string[] = (error.response.data
                        ? error.response.data.errors
                            ? GetValidationStrings(error.response.data.errors)
                            : GetExceptionString(error.response.data)
                        : [error.message as string]) ?? [error.message as string];
                    if (toastErrors) {
                        toastErrors.filter((x) => !!x).forEach((err) => this.appToastsViewStore.addToast(err));
                    }
                }
                return Promise.reject(error);
            }
        );

        return api;
    };
}
