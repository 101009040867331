import classNames from "classnames";
import React from "react";
import { Spinner } from "react-bootstrap";
import { Variant } from "react-bootstrap/esm/types";

const DefaultLoader = (props: { className?: string; variant?: Variant; small?: boolean }) => {
    return (
        <div className={classNames("w-100 text-center h5", props.className)}>
            <Spinner size={props.small ? "sm" : undefined} animation="border" variant={props.variant ?? "secondary"} />
        </div>
    );
};

export default DefaultLoader;
