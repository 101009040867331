import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { inject } from "react-ioc";
import {
    CreateDropdownChoices,
    Distinct,
    DropdownChoice,
    GetDateString,
    GetToday,
    SortChoices,
} from "../../common/api-utils";
import SubscriptionService, { SubscriptionsTypes } from "../../common/subscription-service";
import { TimeEntry, TimeEntryDataStore } from "./time-entry-data.store";

const FilterJson = (clientOptions: DropdownChoice[], userNameOptions: DropdownChoice[]) => {
    return {
        elements: [
            {
                name: "clientName",
                type: "dropdown",
                title: "Which client would you like to view time entries for?",
                choices: clientOptions,
                choicesOrder: "asc",
            },
            {
                name: "userName",
                type: "dropdown",
                title: "Whose time entries would you like to view?",
                choices: userNameOptions,
                choicesOrder: "asc",
            },
        ],
        showQuestionNumbers: "off",
        completedHtml: "<p>Updating...</p>",
        completeText: "Save",
    };
};

export type Client = {
    value: number;
    text: string;
};

export type TimeEntryFilter = {
    clientName?: string;
    userName?: string;
};

export class TimeEntryViewStore {
    @inject private dataStore!: TimeEntryDataStore;
    @inject private subscriptionService!: SubscriptionService;

    search = "";
    selectedDays: Date[];
    filter?: TimeEntryFilter;

    showImportModal = false;
    showExportModal = false;
    importing = false;
    imported = false;
    validating = false;
    validated = false;
    showFilterModal = false;

    constructor() {
        makeObservable(this, {
            search: observable,
            selectedDays: observable,
            showImportModal: observable,
            showExportModal: observable,
            importing: observable,
            imported: observable,
            validating: observable,
            validated: observable,
            filter: observable,
            showFilterModal: observable,
            setSearch: action,
            setSelectedDays: action,
            importFile: action,
            validateFile: action,
            setShowImportModal: action,
            setShowExportModal: action,
            setFilter: action,
            setShowFilterModal: action,
            items: computed,
            datesWithTimeEntry: computed,
            filterJson: computed,
            isInitializing: computed,
        });

        const { month, day, year } = GetToday();
        this.selectedDays = [new Date(year, month - 1, day)];
    }

    getClientUser = (time: { clientUserId: number }) => {
        return this.dataStore.clientUsers.get(time.clientUserId);
    };

    setSearch = (value: string) => {
        this.search = value.toLowerCase();
    };

    setShowImportModal = (value: boolean) => {
        this.showImportModal = value;
        if (!value && !this.validating && !this.importing) {
            this.validated = this.imported = false;
        }
    };

    setShowExportModal = (value: boolean) => {
        this.showExportModal = value;
    };

    setSelectedDays = (value: Date[]) => {
        this.selectedDays = value;
        this.dataStore.lastSelected =
            this.selectedDays[this.selectedDays.length - 1] ??
            (this.dataStore.lastSavedItem?.date ? new Date(`${this.dataStore.lastSavedItem.date} `) : new Date());
        if (this.dataStore.lastSavedItem && !!value.length) {
            this.dataStore.lastSavedItem.date = GetDateString(this.selectedDays[this.selectedDays.length - 1]);
        }
    };

    importFile = async (file: any) => {
        this.importing = true;
        this.imported = false;

        let errors: string[] = [];
        try {
            errors = await this.dataStore.import(file);
        } catch {
            errors = ["File upload failed. Please reselect the file and try again."];
        }

        runInAction(() => {
            this.importing = false;
            if (!errors.length) {
                this.imported = true;
            }
        });

        await Promise.all([this.dataStore.init(), this.subscriptionService.publish(SubscriptionsTypes.TimeList)]);

        return errors;
    };

    downloadTemplate = () => this.dataStore.downloadTemplate();

    downloadInRange = (start: Date, end: Date) => this.dataStore.downloadInRange(start, end);

    validateFile = async (file: any) => {
        this.validating = true;
        this.validated = false;

        let errors: string[] = [];
        try {
            errors = await this.dataStore.validate(file);
        } catch {
            errors = ["File upload failed. Please reselect the file and try again."];
        }

        runInAction(() => {
            this.validating = false;
            if (!errors.length) {
                this.validated = true;
            }
        });
        return errors;
    };

    setFilter = (filter?: TimeEntryFilter) => {
        this.filter = filter?.clientName || filter?.userName ? filter : undefined;
        this.showFilterModal = false;
    };

    setShowFilterModal = (value: boolean) => (this.showFilterModal = value);

    get items() {
        const days = this.selectedDays.length ? this.selectedDays.map((x) => GetDateString(x)) : [];
        return this.dataStore.timeEntries
            .filter((x) => days.includes(x.date))
            .sort((a, b) => this.sortByDate(a, b, () => this.sortByUser(a, b, () => this.sortByClient(a, b))));
    }

    private sortByDate = (a: { date: string }, b: { date: string }, tieSort?: () => 0 | 1 | -1): 0 | -1 | 1 =>
        a.date === b.date ? (tieSort ? tieSort() : 0) : a.date > b.date ? 1 : -1;

    private sortByUser = (
        a: { clientUserId: number },
        b: { clientUserId: number },
        tieSort?: () => 0 | 1 | -1
    ): 0 | -1 | 1 => {
        const aUser = this.dataStore.clientUsers.get(a.clientUserId)?.text ?? "";
        const bUser = this.dataStore.clientUsers.get(b.clientUserId)?.text ?? "";

        return aUser === bUser ? (tieSort ? tieSort() : 0) : aUser > bUser ? 1 : -1;
    };

    private sortByClient = (
        a: { clientUserId: number },
        b: { clientUserId: number },
        tieSort?: () => 0 | 1 | -1
    ): 0 | -1 | 1 => {
        const aClient = this.dataStore.clientUsers.get(a.clientUserId)?.clientId ?? 0;
        const bClient = this.dataStore.clientUsers.get(b.clientUserId)?.clientId ?? 0;

        return aClient === bClient ? (tieSort ? tieSort() : 0) : aClient > bClient ? 1 : -1;
    };

    get filteredItems() {
        return this.filterItems(this.items);
    }

    private filterItems = (entries: TimeEntry[]) =>
        entries
            .map((x) => {
                return {
                    ...x,
                    clientUser: this.getClientUser(x),
                };
            })
            .filter(
                (x) =>
                    (this.filter?.clientName === undefined || x.clientUser?.clientName === this.filter.clientName) &&
                    (this.filter?.userName === undefined || x.clientUser?.text === this.filter.userName)
            );

    get datesWithTimeEntry() {
        return this.filterItems(this.dataStore.timeEntries).map((x) => {
            return { date: new Date(`${x.date} `), percentage: x.hours / 8 };
        });
    }

    get timeForCalendar() {
        return this.dataStore.timeEntries.map((x) => {
            return {
                ...x,
                dateString: x.date,
                date: new Date(x.date + " "),
                value: x.hours,
            };
        });
    }

    get filterJson() {
        const options = {
            clientOptions: SortChoices(
                CreateDropdownChoices(
                    Distinct(Array.from(this.dataStore.clientUsers).map((x) => x[1].clientName)).map((x) => {
                        return { id: x, name: x };
                    })
                )
            ),
            userNameOptions: SortChoices(
                CreateDropdownChoices(
                    Distinct(
                        Array.from(this.dataStore.timeEntries)
                            .map((x) => this.getClientUser(x)?.text)
                            .filter((x) => !!x)
                            .map((x) => {
                                return { id: x!, name: x! };
                            })
                    )
                )
            ),
        };

        return FilterJson(options.clientOptions, options.userNameOptions);
    }

    get isInitializing() {
        return this.dataStore.isInitializing;
    }

    get lastSelected() {
        return this.dataStore.lastSelected;
    }
}
