import { action, makeObservable, observable, runInAction } from "mobx";
import { inject } from "react-ioc";
import {
    ClientUser,
    ConvertFromApiDate,
    ConvertToDate,
    CreateDropdownChoice,
    CreateDropdownChoices,
    DropdownChoice,
    SortChoices,
} from "../../common/api-utils";
import SubscriptionService, { SubscriptionsTypes } from "../../common/subscription-service";
import TimeEntryApi, { TimeEntryApiModel } from "../api/time-entry.api";

export type TimeEntry = {
    id: number;
    clientUserId: number;
    taskTypeId: number;

    hours: number;
    date: string;
    narrative: string;

    location?: number;
};

type ClientDropdownChoice = DropdownChoice & {
    users: DropdownChoice[];
    taskTypes: DropdownChoice[];
};

export class TimeEntryDataStore {
    @inject private subscriptionService!: SubscriptionService;
    @inject private api!: TimeEntryApi;

    isInitializing?: boolean;
    private hasInitialized = false;

    lastSelected?: Date = new Date();
    clientUsers = new Map<number, ClientUser & { userId: number }>();
    timeEntries: TimeEntry[] = [];
    lastSavedItem?: TimeEntry;

    formJsonOptions: ClientDropdownChoice[] = [];
    formLocationOptions: DropdownChoice[] = [];

    constructor() {
        makeObservable(this, {
            isInitializing: observable,
            clientUsers: observable,
            timeEntries: observable,
            lastSelected: observable,
            lastSavedItem: observable,
            init: action,
        });
    }

    init = async () => {
        if (this.isInitializing) {
            return;
        }

        try {
            await this.initInternal();
        } catch (e) {
            runInAction(() => (this.isInitializing = undefined));
            throw e;
        } finally {
            if (!this.hasInitialized) {
                this.subscriptionService.subscribe(
                    [SubscriptionsTypes.ClientUserList, SubscriptionsTypes.TaskList, SubscriptionsTypes.ClientList],
                    this.initInternal
                );
                this.hasInitialized = true;
            }
        }
    };

    private initInternal = async () => {
        this.isInitializing = true;
        const { data } = await this.api.get();

        this.formJsonOptions = SortChoices(
            data.formOptions.map((clientOption) => {
                return {
                    ...CreateDropdownChoice(clientOption),
                    users: CreateDropdownChoices(clientOption.clientUserOptions),
                    taskTypes: CreateDropdownChoices(clientOption.taskTypeOptions),
                };
            })
        );

        this.formLocationOptions = CreateDropdownChoices(data.locations);

        runInAction(() => {
            this.clientUsers.clear();
            data.clientUsers
                .map((x) => {
                    return {
                        value: x.id,
                        text: x.name,
                        clientId: x.clientId,
                        clientName: x.clientName,
                        userId: x.userId,
                    };
                })
                .forEach((x) => this.clientUsers.set(x.value as number, x));
            this.timeEntries = data.timeEntries.map((x) => this.createTimeEntry(x));
            this.isInitializing = false;
        });
    };

    edit = async (model: TimeEntry): Promise<void> => {
        const { data } = await this.api.edit(this.createApiModel(model));
        const index = this.timeEntries.findIndex((x) => x.id === model.id);
        const entry = this.createTimeEntry(data);
        runInAction(() => {
            this.timeEntries.splice(index, 1, entry);
            this.lastSavedItem = entry;
        });
    };

    add = async (model: TimeEntry): Promise<void> => {
        const { data } = await this.api.add(this.createApiModel(model));
        const entry = this.createTimeEntry(data);
        runInAction(() => {
            this.timeEntries.push(entry);
            this.lastSavedItem = entry;
        });
    };

    import = async (file: any) => {
        const { data } = await this.api.import({
            fileName: file.name,
            formFile: file,
        });
        return data;
    };

    downloadTemplate = () => this.api.downloadImportTemplate();

    downloadInRange = (start: Date, end: Date) =>
        this.api.downloadInRange({
            rangeStart: start,
            rangeEnd: end,
        });

    validate = async (file: any) => {
        const { data } = await this.api.validate({
            fileName: file.name,
            formFile: file,
        });
        return data;
    };

    private createApiModel = (time: TimeEntry): TimeEntryApiModel => {
        return {
            id: time.id,
            clientUserId: time.clientUserId,
            taskTypeId: time.taskTypeId,
            hours: time.hours,
            narrative: time.narrative,
            location: time.location,

            date: ConvertToDate(time.date)!,
        };
    };

    private createTimeEntry = (model: TimeEntryApiModel): TimeEntry => {
        return {
            ...model,
            date: ConvertFromApiDate(model.date)!,
        };
    };
}
