import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import React from "react";
import { inject } from "react-ioc";
import DefaultEditPage from "../common/default-edit-page";
import { User } from "./store/user-data.store";
import { UserViewStore } from "./store/user-view.store";

const TableHeader = (
    <thead className="user-select-none">
        <tr>
            {["Name", "Email", "Title", "Login Access", "Assignable to Bills"].map((x) => (
                <th key={`user_header-${x}`} className="border-top-0">
                    {x}
                </th>
            ))}
        </tr>
    </thead>
);

const EditableTableCells = (props: { item: User }) => {
    return (
        <React.Fragment>
            <td hidden>{props.item.id}</td>
            <td>{props.item.name}</td>
            <td>{props.item.email}</td>
            <td>{props.item.title}</td>
            <td>{props.item.role}</td>
            <td className="text-secondary">
                {props.item.active ? <FontAwesomeIcon icon={faCheckCircle} /> : undefined}
            </td>
        </React.Fragment>
    );
};

const MatchesSearch = (user: User, search: string) => {
    return !search || `${user.email}${user.name}${user.role}${user.title}`.toLowerCase().includes(search);
};

@observer
export default class UserLayout extends React.Component {
    @inject private viewStore!: UserViewStore;

    componentDidMount() {
        this.viewStore.init();
    }

    componentWillUnmount() {
        this.viewStore.setSearch("");
    }

    render() {
        const { isInitializing, json, items, editingItem, search, startEditItem, saveItem, setSearch, startAddItem } =
            this.viewStore;

        return (
            <DefaultEditPage
                title="User"
                description="View and edit existing users.  To add a new user, please have someone log into this website."
                json={json}
                editingItem={editingItem}
                onStartEdit={startEditItem}
                onSave={saveItem}
                items={items}
                isInitializing={isInitializing}
                search={search}
                onSetSearch={setSearch}
                onMatchesSearch={MatchesSearch}
                tableHeader={TableHeader}
                tableCells={EditableTableCells}
                onAddButtonClick={startAddItem}
            />
        );
    }
}
