import { action, makeObservable, observable } from "mobx";

type Client = {
    value: number;
    text: string;
};

export class BillClientSelectViewStore {
    selected?: Client;
    search = "";

    constructor() {
        makeObservable(this, {
            search: observable,
            selected: observable,
            setSearch: action,
            setSelected: action,
        });
    }

    setSearch = (value: string) => {
        this.search = value.toLowerCase();
    };

    setSelected = (value?: Client) => (this.selected = value?.value === this.selected?.value ? undefined : value);

    getClientName = (clients: Client[], id: number) => clients.find((x) => x.value === id)?.text;
}
