import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { inject } from "react-ioc";
import { ClientUser, DropdownChoice, SortChoices } from "../../common/api-utils";
import SubscriptionService, { SubscriptionsTypes } from "../../common/subscription-service";
import { Expense, ExpenseDataStore } from "./expense-data.store";

const GenerateJson = (
    clientUserOptions: DropdownChoice[],
    expenseTypeOptions: DropdownChoice[],
    locationOptions: DropdownChoice[]
) => {
    const elements: any[] = [
        {
            name: "clientUserId",
            type: "dropdown",
            title: "Who is the primary person related to this expense?",
            isRequired: true,
            choices: clientUserOptions.map((x) => x),
        },
        {
            name: "expenseTypeId",
            type: "dropdown",
            title: "What type of expense is this?",
            isRequired: true,
            choices: expenseTypeOptions,
        },
        {
            name: "participantNames",
            type: "tagbox",
            title: "Who else participated?",
            choices: clientUserOptions.map((x) => x.text),
        },
        {
            name: "location",
            type: "dropdown",
            title: "Where did this take place?",
            choices: locationOptions,
        },
        {
            name: "date",
            title: "When did this happen?",
            isRequired: true,
            inputType: "date",
            type: "text",
        },
        {
            name: "amount",
            title: "How much?",
            isRequired: true,
            inputType: "number",
            type: "text",
        },
        {
            name: "description",
            title: "Please enter a short description.",
            isRequired: true,
            type: "text",
        },
    ];

    return {
        elements: elements,
        showQuestionNumbers: "off",
        completedHtml: "<p>Updating...</p>",
        completeText: "Save",
    };
};

export class ExpenseViewStore {
    @inject private dataStore!: ExpenseDataStore;
    @inject private subscriptionService!: SubscriptionService;

    isInitializing?: boolean;
    items: Expense[] = [];
    editingItem?: Expense;
    search = "";
    private isCreating = false;
    private clients = new Map<number, ClientUser[]>();
    private generateJson?: (clientUserOptions: DropdownChoice[]) => any;

    showImportModal = false;
    showExportModal = false;
    importing = false;
    imported = false;
    validating = false;
    validated = false;

    constructor() {
        makeObservable(this, {
            isInitializing: observable,
            items: observable,
            editingItem: observable,
            search: observable,
            showImportModal: observable,
            showExportModal: observable,
            importing: observable,
            imported: observable,
            validating: observable,
            validated: observable,
            init: action,
            startEditItem: action,
            startAddItem: action,
            setSearch: action,
            importFile: action,
            validateFile: action,
            setShowImportModal: action,
            setShowExportModal: action,
            expensesForCalendar: computed,
        });
    }

    init = async () => {
        if (this.isInitializing !== undefined) {
            return;
        }

        try {
            await this.initInteral();
        } catch (e) {
            runInAction(() => (this.isInitializing = undefined));
            throw e;
        } finally {
            this.subscriptionService.subscribe(
                [SubscriptionsTypes.ExpenseTypeList, SubscriptionsTypes.ClientUserList],
                this.initInteral
            );
        }
    };

    private initInteral = async () => {
        this.isInitializing = true;
        const data = await this.dataStore.get();

        this.clients.clear();
        data.clientUsers.forEach((x) => {
            if (!this.clients.has(x.clientId)) {
                this.clients.set(x.clientId, [x]);
            } else {
                this.clients.get(x.clientId)!.push(x);
            }
        });

        this.generateJson = (clientUserOptions: DropdownChoice[]) =>
            GenerateJson(clientUserOptions, data.expenseTypes, data.locations);

        runInAction(() => {
            this.items = data.expenses;
            this.isInitializing = false;
        });
    };

    startEditItem = (expense?: Expense) => {
        this.editingItem = expense;
        this.isCreating = false;
    };

    startAddItem = () => {
        const date = new Date();
        const [month, day, year] = [date.getMonth() + 1, date.getDate(), date.getFullYear()];

        this.editingItem = {
            id: 0,
            active: true,
            clientUserId: 0,
            amount: 0,
            description: "",
            expenseTypeId: 0,
            date: `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}`,
            participantNames: [],
        };
        this.isCreating = true;
    };

    saveItem = async (expense: Expense) => {
        const index = this.items.findIndex((x) => x.id === expense.id);

        try {
            const updated = this.isCreating ? await this.dataStore.add(expense) : await this.dataStore.edit(expense);

            runInAction(() => {
                if (~index) {
                    this.items.splice(index, 1, updated);
                } else {
                    this.items.push(updated);
                }
                this.startEditItem(undefined);
            });
        } catch (e) {
            runInAction(() => {
                this.startEditItem(undefined);
            });
            throw e;
        }

        this.subscriptionService.publish(SubscriptionsTypes.ExpenseList);
    };

    setSearch = (value: string) => {
        this.search = value.toLowerCase();
    };

    setShowImportModal = (value: boolean) => {
        this.showImportModal = value;
        if (!value && !this.validating && !this.importing) {
            this.validated = this.imported = false;
        }
    };

    setShowExportModal = (value: boolean) => {
        this.showExportModal = value;
    };

    getJson = (clientId?: number) => {
        const clientUsers = this.clients.get(clientId ?? -1);
        return this.generateJson?.(clientUsers ?? []);
    };

    getClientUser = (expense: Expense) => {
        return Array.from(this.clients.values())
            .flatMap((x) => x)
            .find((x) => x.value === expense.clientUserId);
    };

    importFile = async (file: any) => {
        this.importing = true;
        this.imported = false;

        let errors: string[] = [];
        try {
            errors = await this.dataStore.import(file);
        } catch {
            errors = ["File upload failed. Please reselect the file and try again."];
        }

        runInAction(() => {
            this.importing = false;
            if (!errors.length) {
                this.imported = true;
            }
        });

        this.initInteral();

        this.subscriptionService.publish(SubscriptionsTypes.ExpenseList);

        return errors;
    };

    downloadTemplate = () => this.dataStore.downloadTemplate();

    downloadInRange = (start: Date, end: Date) => this.dataStore.downloadInRange(start, end);

    validateFile = async (file: any) => {
        this.validating = true;
        this.validated = false;

        let errors: string[] = [];
        try {
            errors = await this.dataStore.validate(file);
        } catch {
            errors = ["File upload failed. Please reselect the file and try again."];
        }

        runInAction(() => {
            this.validating = false;
            if (!errors.length) {
                this.validated = true;
            }
        });
        return errors;
    };

    get clientList() {
        return SortChoices(
            Array.from(this.clients).map((x) => {
                return {
                    value: x[0],
                    text: x[1][0].clientName,
                };
            })
        );
    }

    get expensesForCalendar() {
        return this.items.map((x) => {
            return {
                ...x,
                dateString: x.date,
                date: new Date(x.date + " "),
                value: x.amount,
            };
        });
    }

    get sortedItems() {
        return this.items
            .map((x) => {
                return { ...x, clientUser: this.getClientUser(x) };
            })
            .sort((a, b) => (a.date > b.date ? -1 : a.date === b.date ? 0 : 1));
    }
}
