import { inject } from "react-ioc";
import {
    ClientUser,
    ConvertFromApiDate,
    ConvertToDate,
    CreateDropdownChoices,
    DropdownChoice,
    SortChoices,
} from "../../common/api-utils";
import ExpenseApi, { ExpenseApiModel } from "../api/expense.api";

export type Expense = {
    id: number;
    active: boolean;

    clientUserId: number;
    expenseTypeId: number;

    participantNames: string[];

    date: string;
    expenseMonth?: string;
    billedMonth?: string;

    amount: number;
    location?: number;

    description: string;
};

export class ExpenseDataStore {
    @inject private api!: ExpenseApi;
    private clientUsers = new Map<number, ClientUser>();

    get = async (): Promise<{
        expenses: Expense[];
        clientUsers: ClientUser[];
        expenseTypes: DropdownChoice[];
        locations: DropdownChoice[];
    }> => {
        const { data } = await this.api.get();
        const clientUsers = SortChoices(
            data.clientUsers.map((x) => {
                return {
                    value: x.id,
                    text: x.name,
                    clientId: x.clientId,
                    clientName: x.clientName,
                };
            })
        );

        this.clientUsers = new Map<number, ClientUser>(clientUsers.map((x) => [x.value, x]));

        return {
            expenses: data.expenses.map((x) => this.createExpense(x)),
            clientUsers,
            expenseTypes: CreateDropdownChoices(data.expenseTypes),
            locations: CreateDropdownChoices(data.locations),
        };
    };

    edit = async (expense: Expense): Promise<Expense> => {
        const { data } = await this.api.edit(this.createApiModel(expense));
        return this.createExpense(data);
    };

    add = async (expense: Expense): Promise<Expense> => {
        const { data } = await this.api.add(this.createApiModel(expense));
        return this.createExpense(data);
    };

    import = async (file: any) => {
        const { data } = await this.api.import({
            fileName: file.name,
            formFile: file,
        });
        return data;
    };

    downloadTemplate = () => this.api.downloadImportTemplate();

    downloadInRange = (start: Date, end: Date) =>
        this.api.downloadInRange({
            rangeStart: start,
            rangeEnd: end,
        });

    validate = async (file: any) => {
        const { data } = await this.api.validate({
            fileName: file.name,
            formFile: file,
        });
        return data;
    };

    private createApiModel = (expense: Expense): ExpenseApiModel => {
        const values = Array.from(this.clientUsers.values());
        const ids =
            expense.participantNames
                ?.map((x) => values.find((y) => y.text === x))
                .filter((x) => x !== undefined)
                .map((x) => x!.value as number) ?? [];

        return {
            ...expense,
            date: ConvertToDate(expense.date)!,
            expenseMonth: ConvertToDate(expense.expenseMonth),
            billedMonth: ConvertToDate(expense.billedMonth),
            participantUserIds: ids,
        };
    };

    private createExpense = (model: ExpenseApiModel): Expense => {
        const names = model.participantUserIds
            ?.filter((x) => this.clientUsers.has(x))
            .map((x) => this.clientUsers.get(x)!.text);

        return {
            ...model,
            date: ConvertFromApiDate(model.date)!,
            expenseMonth: ConvertFromApiDate(model.expenseMonth),
            billedMonth: ConvertFromApiDate(model.billedMonth),
            participantNames: names ?? [],
        };
    };
}
