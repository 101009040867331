import { action, makeObservable, observable } from "mobx";
import { Time } from "./bill-data.store";

export class BillTimeSelectViewStore {
    selectedTimeEntries: number[] = [];
    search = "";

    constructor() {
        makeObservable(this, {
            search: observable,
            selectedTimeEntries: observable,
            setSearch: action,
            toggleSelectedItem: action,
        });
    }

    setSearch = (value: string) => {
        this.search = value.toLowerCase();
    };

    matchesSearch = (value: Time) => {
        return !this.search || `${value.narrative}${value.date}`.toLowerCase().includes(this.search);
    };

    toggleSelectedItem = (value: Time) => {
        const index = this.selectedTimeEntries.findIndex((x) => x === value.id);
        if (~index) {
            this.selectedTimeEntries.splice(index, 1);
        } else {
            this.selectedTimeEntries.push(value.id);
        }
    };
}
