import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { Table } from "react-bootstrap";
import { useInstance } from "react-ioc";
import SearchBar from "../common/search-bar";
import { ClientUser } from "./store/client-user-data.store";
import { ClientUserViewStore } from "./store/client-user-view.store";

const TableHeader = (
    <thead className="user-select-none">
        <tr>
            {["Name", "Title", "Rate", "Cap"].map((x) => (
                <th key={`clientuser_header-${x}`} className="border-top-0">
                    {x}
                </th>
            ))}
        </tr>
    </thead>
);

const EditableTableCells = (props: { item: ClientUser }) => {
    return (
        <React.Fragment>
            <td hidden>{props.item.id}</td>
            <td>{props.item.name}</td>
            <td>{props.item.title ?? props.item.defaultTitle}</td>
            <td>{props.item.rate}</td>
            <td>{props.item.cap}</td>
        </React.Fragment>
    );
};

const MatchesSearch = (user: ClientUser, search: string) => {
    return !search || `${user.name}${user.title}`.toLowerCase().includes(search);
};

const ClientUserSelect = observer(() => {
    const { isInitializing, items, search, editingItem, selectedClient, setSearch, startEditItem, startAddItem } =
        useInstance(ClientUserViewStore);

    return (
        <React.Fragment>
            <SearchBar fullWidth search={search} setSearch={setSearch} onAddButtonClick={startAddItem} />
            <Table borderless hover striped className="user-select-none">
                {TableHeader}
                {!isInitializing ? (
                    <tbody className="cursor-pointer">
                        {items
                            .filter((x) => x.clientId === selectedClient?.value && MatchesSearch(x, search))
                            .map((item) => (
                                <tr
                                    onClick={() => {
                                        startEditItem(item);
                                    }}
                                    className={classNames({ "bg-primary": item.id === editingItem?.id })}
                                >
                                    <EditableTableCells item={item} />
                                </tr>
                            ))}
                    </tbody>
                ) : undefined}
            </Table>
        </React.Fragment>
    );
});

export default ClientUserSelect;
