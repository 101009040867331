import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { inject } from "react-ioc";
import AppViewStore from "../../../stores/app.view.store";
import { DropdownChoice, GetDateString } from "../../common/api-utils";
import SubscriptionService, { SubscriptionsTypes } from "../../common/subscription-service";
import { TimeEntry, TimeEntryDataStore } from "./time-entry-data.store";

const GenerateJson = (
    clientOptions: DropdownChoice[],
    userOptions: DropdownChoice[],
    taskOptions: DropdownChoice[],
    locationOptions: DropdownChoice[],
    isAdmin: boolean,
    isAdding: boolean
) => {
    const elements: any[] = [
        {
            name: "clientId",
            type: "dropdown",
            title: "Which client is this time record for?",
            isRequired: true,
            choices: clientOptions,
            choicesOrder: "asc",
            showOptionsCaption: false,
        },
        {
            name: "clientUserId",
            type: "dropdown",
            title: "Whose time is this?",
            isRequired: true,
            choices: userOptions,
            choicesOrder: "asc",
            showOptionsCaption: false,
        },
        {
            name: "taskTypeId",
            type: "dropdown",
            title: "What sort of task was this?",
            isRequired: true,
            choices: taskOptions,
            choicesOrder: "asc",
            showOptionsCaption: false,
            visibleIf: "{clientId} <> '0'",
        },
        {
            name: "date",
            title: "When did this happen?",
            isRequired: true,
            inputType: "date",
            type: "text",
            min: "2022-06-01",
            maxValueExpression: "today(60)",
            minErrorText: "Cannot add time before June 1st, 2022.",
            maxErrorText: "Cannot add time over 60 days in the future.",
        },
        {
            name: "hours",
            title: "How many hours was this task?",
            isRequired: true,
            inputType: "number",
            type: "text",
            max: "24",
            min: "0",
            maxErrorText: "Even your worst days only have 24 hours.",
        },
        {
            name: "location",
            type: "dropdown",
            title: "Where did this happen?",
            isRequired: true,
            choices: locationOptions,
        },
        {
            name: "narrative",
            title: "Please enter a description.",
            isRequired: true,
            type: "comment",
            requiredErrorText: "There's always a story.",
        },
    ];

    if (!isAdding && !isAdmin) {
        elements.splice(1, 1);
    }

    return {
        elements: elements,
        showQuestionNumbers: "off",
        completedHtml: "<p>Updating...</p>",
        completeText: "Save",
        clearInvisibleValues: "onHidden",
    };
};

export type EditableTimeEntry = Omit<TimeEntry, "hours" | "clientUserId" | "taskTypeId"> & {
    clientId?: number;
    hours?: number;
    clientUserId?: number;
    taskTypeId?: number;
};

export class TimeEntrySurveyStore {
    @inject private dataStore!: TimeEntryDataStore;
    @inject private appStore!: AppViewStore;
    @inject private subscriptionService!: SubscriptionService;

    editingItem?: EditableTimeEntry;

    selectedClient?: number;

    isCreating = false;

    constructor() {
        makeObservable(this, {
            editingItem: observable,
            isCreating: observable,
            selectedClient: observable,
            startEditItem: action,
            startAddItem: action,
            onEditValueChanging: action,
            json: computed,
            isInitializing: computed,
        });
    }

    startEditItem = (time?: EditableTimeEntry) => {
        const user = time?.clientUserId ? this.dataStore.clientUsers.get(time.clientUserId) : undefined;
        this.editingItem = time && { ...time, clientId: user?.clientId };
        this.isCreating = false;
        this.selectedClient = user?.clientId;
    };

    startAddItem = () => {
        const clientUser = this.dataStore.lastSavedItem?.clientUserId
            ? this.dataStore.clientUsers.get(this.dataStore.lastSavedItem.clientUserId)
            : undefined;

        this.editingItem = {
            id: 0,
            clientId: clientUser?.clientId,
            clientUserId: clientUser?.value as number,
            taskTypeId: this.dataStore.lastSavedItem?.taskTypeId,
            date: this.dataStore.lastSavedItem?.date ?? GetDateString(new Date()),
            location: this.dataStore.lastSavedItem?.location,
            narrative: "",
        };
        this.isCreating = true;
        this.selectedClient = clientUser?.clientId;
    };

    private getClientUser = (newClientId?: number, oldClientUserId?: number) => {
        if (newClientId === undefined || oldClientUserId === undefined) {
            return undefined;
        }

        const userId = this.dataStore.clientUsers.get(oldClientUserId)?.userId;
        if (userId === undefined) {
            return undefined;
        }

        return Array.from(this.dataStore.clientUsers?.entries() ?? []).find(
            (x) => x[1].clientId === newClientId && x[1].userId === userId
        );
    };

    saveItem = async (time: EditableTimeEntry) => {
        runInAction(() => (this.dataStore.lastSelected = new Date(`${time.date} `)));

        try {
            const apiTimeItem = {
                ...time,
                hours: time.hours ?? 0,
                clientUserId: time.clientUserId!,
                taskTypeId: time.taskTypeId!,
            };
            const action = this.isCreating
                ? () => this.dataStore.add(apiTimeItem)
                : () => this.dataStore.edit(apiTimeItem);

            await action();
        } catch (e) {
            throw e;
        } finally {
            runInAction(() => {
                this.startEditItem(undefined);
            });
        }

        this.subscriptionService.publish(SubscriptionsTypes.TimeList);
    };

    onEditValueChanging = (question: string, value: number, data: any) => {
        if (question !== "clientId" || this.editingItem === undefined) {
            return [];
        }

        const newSelectedOption = this.dataStore.formJsonOptions.find((x) => x.value === value);
        const currentClientUserId = data["clientUserId"] ?? this.editingItem.clientUserId;
        const currentTaskTypeId = data["taskTypeId"] ?? this.editingItem.taskTypeId;

        this.selectedClient = value;
        return {
            clientId: value,
            clientUserId: this.getClientUser(value, currentClientUserId)?.[0],
            taskTypeId: newSelectedOption?.taskTypes.find((x) => x.value === currentTaskTypeId)?.value as number,
        };
    };

    get json() {
        const { isAdmin } = this.appStore;
        const selectedClientOption =
            this.selectedClient !== undefined
                ? this.dataStore.formJsonOptions.find((x) => x.value === this.selectedClient)
                : undefined;

        return GenerateJson(
            this.dataStore.formJsonOptions,
            selectedClientOption?.users ?? [],
            selectedClientOption?.taskTypes ?? [],
            this.dataStore.formLocationOptions,
            isAdmin,
            this.isCreating
        );
    }

    get isInitializing() {
        return this.dataStore.isInitializing;
    }
}
