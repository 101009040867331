import { AxiosResponse } from "axios";
import { inject } from "react-ioc";
import { NamedIdApiModel } from "../../common/api-utils";
import AuthorizedAxios from "../../common/authorized-axios";

class ClientUserApi {
    private controller = "clientuserapi";
    @inject axios!: AuthorizedAxios;

    get = async (): Promise<AxiosResponse<ClientUserFormApiModel>> => {
        return (await this.axios.createInstance()).get(`${this.controller}/get`);
    };

    add = async (model: EditClientUserApiModel): Promise<AxiosResponse<ClientUserApiModel>> => {
        return (await this.axios.createInstance()).post(`${this.controller}/add`, model, {
            responseType: "json",
        });
    };

    edit = async (model: EditClientUserApiModel): Promise<AxiosResponse<ClientUserApiModel>> => {
        return (await this.axios.createInstance()).post(`${this.controller}/edit`, model, {
            responseType: "json",
        });
    };
}

interface ClientUserFormApiModel {
    clientUsers: ClientUserApiModel[];
    classTypes: NamedIdApiModel[];
    users: NamedIdApiModel[];
    clients: NamedIdApiModel[];
}

export interface ClientUserApiModel extends EditClientUserApiModel {
    defaultTitle: string;
}

export interface EditClientUserApiModel {
    id: number;
    clientId: number;
    userId: number;
    name: string;

    rate?: number;
    cap?: number;
    title?: string;
    class?: number;
}

export default ClientUserApi;
