import React from "react";
import { SurveyModel, Survey } from "survey-react";
import DefaultSaveButton from "../../common/default-save-button";
import { DollarFormatter } from "../../common/formatters";
import { Bill } from "../store/bill-data.store";
import { AccordionItem } from "../store/bill-view.store";
import BillAccordionItem from "./bill-accordion-item";

interface Props {
    detailSurveyModel: SurveyModel;
    selectedItem: AccordionItem;
    selectedBill?: Bill;
    setSelectedItem: (item: AccordionItem) => void;
    onSurveyCompleted: (bill: Bill) => void;
}

const BillAccordionItemBillDetails = (props: Props) => {
    const currentItemIsAfterBillDetails =
        props.selectedItem === "none" ||
        props.selectedItem === "bill-discounts" ||
        props.selectedItem === "bill-time" ||
        props.selectedItem === "bill-expenses";

    return (
        <BillAccordionItem
            className="mt-3"
            title="Bill Details"
            description="Please fill out or update the basic details below."
            itemType="bill-details"
            selectedItemType={props.selectedItem}
            showEdit={currentItemIsAfterBillDetails}
            showSummary={currentItemIsAfterBillDetails && !!props.selectedBill}
            onEdit={props.setSelectedItem}
            headerSummary={
                <div className="w-100">
                    <div className="w-100 d-flex mt-2">
                        <div className="w-25">
                            <p className="mb-1 text-subtle">Bill Status</p>
                            <p>{props.selectedBill?.locked ? "Locked" : "Unlocked"}</p>
                        </div>
                        <div className="w-25">
                            <p className="mb-1 text-subtle">Coverage Start Date</p>
                            <p>{props.selectedBill?.coverageStartDate}</p>
                        </div>
                        <div className="w-25">
                            <p className="mb-1 text-subtle">Coverage End Date</p>
                            <p>{props.selectedBill?.coverageEndDate}</p>
                        </div>
                        <div className="w-25">
                            <p className="mb-1 text-subtle">Billed Date</p>
                            <p>{props.selectedBill?.billedDate ? props.selectedBill.billedDate : "None"}</p>
                        </div>
                    </div>
                    <div className="w-100 d-flex mt-2">
                        <div className="w-25">
                            <p className="mb-1 text-subtle">Bill Cap</p>
                            <p>
                                {!!props.selectedBill?.totalCap
                                    ? DollarFormatter.format(props.selectedBill.totalCap)
                                    : "None"}
                            </p>
                        </div>
                    </div>
                </div>
            }
            body={
                <div className="w-100 d-flex flex-column">
                    <Survey
                        model={props.detailSurveyModel}
                        data={{ ...props.selectedBill }}
                        onComplete={(result: SurveyModel) => result.data?.id && props.onSurveyCompleted(result.data)}
                    />
                    <DefaultSaveButton mlauto onClick={() => props.detailSurveyModel.doComplete()} />
                </div>
            }
        />
    );
};

export default BillAccordionItemBillDetails;
