import classNames from "classnames";
import React from "react";
import { Accordion, Button } from "react-bootstrap";
import { AccordionItem } from "../store/bill-view.store";

const BillAccordionItem = (props: {
    className?: string;
    title: string;
    description: string;
    selectedItemType: AccordionItem;
    itemType: AccordionItem;
    showEdit: boolean;
    showSummary: boolean;
    headerSummary: JSX.Element;
    body: JSX.Element;
    onEdit: (item: AccordionItem) => void;
}) => {
    return (
        <Accordion.Item eventKey={props.itemType} className={classNames("rounded-0 border-top", props.className)}>
            <Accordion.Header className="user-select-none">
                <div className="d-flex justify-content-between w-100 align-items-center">
                    <div className="w-100">
                        <p
                            className={classNames("font-weight-bold", {
                                "text-disabled": !props.showSummary && props.selectedItemType !== props.itemType,
                                "mb-0": !props.showSummary && props.selectedItemType !== props.itemType,
                                "text-secondary": props.showSummary,
                            })}
                        >
                            {props.title}
                        </p>
                        {props.selectedItemType === props.itemType ? (
                            <div className="w-50 align-items-center d-flex mt-2 mr-auto">
                                <small>{props.description}</small>
                            </div>
                        ) : undefined}
                    </div>
                    <Button
                        variant="secondary-outline"
                        className={classNames("rounded-0", { "v-hidden": !props.showEdit })}
                        size="sm"
                        onClick={() => props.showEdit && props.onEdit(props.itemType)}
                    >
                        Edit
                    </Button>
                </div>
                {props.showSummary ? (
                    <div className="w-100 align-items-center d-flex mt-2">{props.headerSummary}</div>
                ) : undefined}
            </Accordion.Header>
            <Accordion.Body>{props.body}</Accordion.Body>
        </Accordion.Item>
    );
};

export default BillAccordionItem;
