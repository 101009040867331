import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { inject } from "react-ioc";
import { SurveyModel } from "survey-react";
import { GetDateString } from "../../common/api-utils";
import SubscriptionService, { SubscriptionsTypes } from "../../common/subscription-service";
import { BillClientSelectViewStore } from "./bill-client-select-view.store";
import { Bill, BillDataStore, Client, Discount, Expense, Time } from "./bill-data.store";

const json = {
    elements: [
        {
            name: "locked",
            type: "boolean",
            title: "Is this bill locked?",
        },
        {
            name: "coverageStartDate",
            title: "When is the start date for this bill?",
            inputType: "date",
            isRequired: true,
            type: "text",
        },
        {
            name: "coverageEndDate",
            title: "When is the end date for this bill?",
            inputType: "date",
            isRequired: true,
            type: "text",
        },
        {
            name: "billedDate",
            title: "When was this bill sent out?",
            inputType: "date",
            type: "text",
        },
        {
            name: "totalCap",
            title: "What amount should this bill be capped at?",
            inputType: "number",
            type: "text",
        },
    ],
    showQuestionNumbers: "off",
    completedHtml: "<p>Updating...</p>",
    completeText: "Save",
    showNavigationButtons: false,
    showCompletedPage: false,
};

export type AccordionItem = "none" | "bill-select" | "bill-details" | "bill-discounts" | "bill-time" | "bill-expenses";

export class BillViewStore {
    @inject private dataStore!: BillDataStore;
    @inject private clientSelectStore!: BillClientSelectViewStore;
    @inject private subscriptionService!: SubscriptionService;

    isInitializing?: boolean;
    generatingReport = false;
    accordionItem: AccordionItem = "bill-select";

    bills: Bill[] = [];
    clients: Client[] = [];
    time: Time[] = [];
    expenses: Expense[] = [];

    selectedTimes: Time[] = [];
    selectedExpenses: Expense[] = [];
    detailSurveyModel = new SurveyModel(json);

    constructor() {
        makeObservable(this, {
            isInitializing: observable,
            generatingReport: observable,
            accordionItem: observable,
            selectedTimes: observable,
            selectedExpenses: observable,
            bills: observable,
            time: observable,
            expenses: observable,
            init: action,
            setAccordionItem: action,
            createBill: action,
            onSurveyCompleted: action,
            generateReport: action,
            items: computed,
            timeForSelectedClient: computed,
        });
    }

    init = async () => {
        if (this.isInitializing !== undefined) {
            return;
        }

        try {
            await this.initInteral();
        } catch (e) {
            runInAction(() => (this.isInitializing = undefined));
            throw e;
        } finally {
            this.subscriptionService.subscribe(
                [
                    SubscriptionsTypes.ExpenseList,
                    SubscriptionsTypes.TaskList,
                    SubscriptionsTypes.TimeList,
                    SubscriptionsTypes.ClientList,
                    SubscriptionsTypes.ClientUserList,
                ],
                this.initInteral
            );
        }
    };

    setAccordionItem = (item: AccordionItem) => {
        this.accordionItem = item;
    };

    createBill = async () => {
        const date = new Date();
        const newBill = await this.dataStore.add({
            id: 0,
            clientId: this.clientSelectStore.selected!.value,
            locked: false,
            coverageStartDate: GetDateString(new Date(date.getFullYear(), date.getMonth(), 1)),
            coverageEndDate: GetDateString(new Date(date.getFullYear(), date.getMonth() + 1, 0)),
            timeIds: [],
            expenseIds: [],
            discounts: [],
            totalCap: 0,
        });

        runInAction(() => {
            this.bills.push(newBill);
        });
    };

    toggleSelectedTime = (time: Time) => {
        const index = this.selectedTimes.findIndex((x) => x.id === time.id);
        if (~index) {
            this.selectedTimes.splice(index, 1);
        } else {
            this.selectedTimes.push(time);
        }
    };

    toggleSelectedExpense = (expense: Expense) => {
        const index = this.selectedExpenses.findIndex((x) => x.id === expense.id);
        if (~index) {
            this.selectedExpenses.splice(index, 1);
        } else {
            this.selectedExpenses.push(expense);
        }
    };

    onSurveyCompleted = async (bill: Bill) => {
        this.detailSurveyModel.clear();

        const index = this.bills.findIndex((x) => x.id === bill.id);
        const updatedBill = await this.dataStore.edit(bill);

        runInAction(() => {
            this.bills.splice(index, 1, updatedBill);
        });
    };

    updateBillDiscount = (discount: Discount) => this.dataStore.updateBillDiscount(discount);

    generateReport = async (bill?: Bill) => {
        if (!bill) {
            return;
        }

        this.generatingReport = true;
        try {
            await this.dataStore.generateReport(bill);
        } finally {
            runInAction(() => {
                this.generatingReport = false;
            });
        }
    };

    get items() {
        return !!this.clientSelectStore.selected
            ? this.bills.filter((x) => this.clientSelectStore.selected!.value === x.clientId)
            : [];
    }

    get timeForSelectedClient() {
        return !!this.clientSelectStore.selected
            ? this.time.filter((x) => this.clientSelectStore.selected!.value === x.clientId)
            : [];
    }

    get expensesForSelectedClient() {
        return !!this.clientSelectStore.selected
            ? this.expenses.filter((x) => this.clientSelectStore.selected!.value === x.clientId)
            : [];
    }

    private initInteral = async () => {
        this.isInitializing = true;
        const data = await this.dataStore.get();

        runInAction(() => {
            this.clients = data.clients;
            this.expenses = data.expenses;
            this.time = data.time;
            this.bills = data.bills;
            this.isInitializing = false;
        });
    };
}
