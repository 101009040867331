import { faFileDownload, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useState } from "react";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GetToday } from "./api-utils";
import Calendar from "./external/react-calendar/components/calendar/Calendar";

const DefaultExportRangeModal = (props: {
    show: boolean;
    title: string;
    formatValue: (value: number) => string;
    onExport: (startDate: Date, endDate: Date) => Promise<void>;
    onHide: () => void;
    items?: { date: Date; dateString: string; value: number }[];
    mlauto?: boolean;
    className?: string;
    valueName?: string;
}) => {
    const [selectedDays, setSelectedDays] = useState<Date[]>(() => {
        const { month, day, year } = GetToday();
        return [new Date(year, month - 1, day), new Date(year, month - 1, day)];
    });
    const [exporting, setExporting] = useState<boolean>(false);

    const onHide = () => {
        setSelectedDays([]);
        setExporting(false);
        props.onHide();
    };

    const inRange =
        (selectedDays.length === 2
            ? props.items?.filter((x) => x.date >= selectedDays[0] && x.date <= selectedDays[1])
            : undefined) ?? [];

    const start = selectedDays?.[0] ?? new Date();
    const end = selectedDays?.[1] ?? new Date();

    return (
        <Modal show={props.show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex">
                <div className="d-flex flex-column">
                    <Calendar
                        disableFuture
                        isRangeSelector
                        className="rounded-0 shadow-none mx-auto"
                        size={400}
                        startOfWeek={0}
                        highlights={props.items}
                        value={[start, end]}
                        onChange={(value) =>
                            setSelectedDays((value as Date[]).sort((a, b) => (a > b ? 1 : a === b ? 0 : -1)))
                        }
                    />
                    <div
                        className="d-flex mt-2 border"
                        style={{
                            borderColor: "#f3f3f3",
                            fontSize: "0.83em",
                        }}
                    >
                        <DatePicker
                            name="expense_range_start"
                            className="rounded-0 shadow-none mr-1 border-0 text-center py-1 w-100"
                            selected={start}
                            onChange={(date) =>
                                setSelectedDays(
                                    [date && date > end ? end : date, end]
                                        .filter((x) => x)
                                        .map((x) => x!)
                                        .sort((a, b) => (a > b ? 1 : a === b ? 0 : -1))
                                )
                            }
                        />
                        <p className="my-auto p-0">
                            <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </p>
                        <DatePicker
                            name="expense_range_end"
                            className="rounded-0 shadow-none border-0 text-center py-1 w-100"
                            selected={end}
                            onChange={(date) =>
                                setSelectedDays(
                                    [start, date && date < start ? start : date]
                                        .filter((x) => x)
                                        .map((x) => x!)
                                        .sort((a, b) => (a > b ? 1 : a === b ? 0 : -1))
                                )
                            }
                        />
                    </div>
                </div>
                <Table borderless className="m-0">
                    <tbody className="d-flex flex-column overflow-y-scroll" style={{ maxHeight: 400 }}>
                        {inRange
                            .sort((a, b) => (a.date > b.date ? 1 : a.date === b.date ? 0 : -1))
                            .map((x) => {
                                return (
                                    <tr
                                        key={`export-range-modal-${x.dateString}`}
                                        className={classNames(
                                            "d-flex border-3 border-right-0 border-top-0 border-bottom-0 border-info"
                                        )}
                                    >
                                        <td className="w-50 border-top-0 border-bottom">{x.dateString}</td>
                                        <td
                                            className={classNames("border-top-0 border-bottom text-right", {
                                                "w-25": props.valueName,
                                                "w-50": !props.valueName,
                                            })}
                                        >
                                            {props.formatValue(x.value)}
                                        </td>
                                        {props.valueName ? (
                                            <td className="w-25 border-top-0 border-bottom pl-0">{props.valueName}</td>
                                        ) : undefined}
                                    </tr>
                                );
                            })}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={selectedDays.length !== 2 || exporting}
                    variant="primary-outline"
                    className="ml-auto"
                    onClick={async () => {
                        setExporting(true);
                        await props.onExport(selectedDays[0], selectedDays[1]);
                        setExporting(false);
                        onHide();
                    }}
                >
                    <FontAwesomeIcon icon={faFileDownload} className="mr-1" />
                    {exporting ? <Spinner size="sm" animation="border" variant="primary" className="ml-1" /> : "Export"}
                </Button>
                <Button variant="secondary-outline" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DefaultExportRangeModal;
