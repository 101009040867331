import { observer } from "mobx-react";
import React from "react";
import { inject, provider } from "react-ioc";
import { Redirect, Route } from "react-router";
import * as Survey from "survey-react";
import * as widgets from "surveyjs-widgets";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import BillApi from "./components/bill/api/bill.api";
import BillLayout from "./components/bill/bill-layout";
import { BillClientSelectViewStore } from "./components/bill/store/bill-client-select-view.store";
import { BillDataStore } from "./components/bill/store/bill-data.store";
import { BillDiscountSelectViewStore } from "./components/bill/store/bill-discount-select-view.store";
import { BillExpenseImportViewStore } from "./components/bill/store/bill-expense-import-view.store";
import { BillExpenseSelectViewStore } from "./components/bill/store/bill-expense-select-view.store";
import { BillSelectViewStore } from "./components/bill/store/bill-select-view.store";
import { BillTimeImportViewStore } from "./components/bill/store/bill-time-import-view.store";
import { BillTimeSelectViewStore } from "./components/bill/store/bill-time-select-view.store";
import { BillViewStore } from "./components/bill/store/bill-view.store";
import ClientUserApi from "./components/client-user/api/client-user.api";
import ClientUserLayout from "./components/client-user/client-user-layout";
import { ClientUserDataStore } from "./components/client-user/store/client-user-data.store";
import { ClientUserViewStore } from "./components/client-user/store/client-user-view.store";
import ClientApi from "./components/client/api/client.api";
import ClientLayout from "./components/client/client-layout";
import { ClientDataStore } from "./components/client/store/client-data.store";
import { ClientViewStore } from "./components/client/store/client-view.store";
import AppToasts from "./components/common/app-toasts/app-toasts";
import AppToastsViewStore from "./components/common/app-toasts/store/app-toasts-view.store";
import AuthorizedAxios from "./components/common/authorized-axios";
import DefaultLoader from "./components/common/default-loader";
import SubscriptionService from "./components/common/subscription-service";
import ExpenseTypeApi from "./components/expense-type/api/expense-type.api";
import ExpenseTypeLayout from "./components/expense-type/expense-type-layout";
import { ExpenseTypeDataStore } from "./components/expense-type/store/expense-type-data.store";
import { ExpenseTypeViewStore } from "./components/expense-type/store/expense-type-view.store";
import ExpenseApi from "./components/expense/api/expense.api";
import ExpenseLayout from "./components/expense/expense-layout";
import { ExpenseClientSelectViewStore } from "./components/expense/store/expense-client-select-view.store";
import { ExpenseDataStore } from "./components/expense/store/expense-data.store";
import { ExpenseViewStore } from "./components/expense/store/expense-view.store";
import { Home } from "./components/Home";
import { Layout } from "./components/Layout";
import TaskTypeApi from "./components/tasktype/api/tasktype.api";
import { TaskTypeDataStore } from "./components/tasktype/store/tasktype-data.store";
import { TaskTypeViewStore } from "./components/tasktype/store/tasktype-view.store";
import TaskTypeLayout from "./components/tasktype/tasktype-layout";
import TimeEntryApi from "./components/time/api/time-entry.api";
import { TimeEntryDataStore } from "./components/time/store/time-entry-data.store";
import { TimeEntrySurveyStore } from "./components/time/store/time-entry-survey.store";
import { TimeEntryViewStore } from "./components/time/store/time-entry-view.store";
import TimeEntryLayout from "./components/time/time-entry-layout";
import UserApi from "./components/user/api/user.api";
import { UserDataStore } from "./components/user/store/user-data.store";
import { UserViewStore } from "./components/user/store/user-view.store";
import UserLayout from "./components/user/user-layout";
import "./custom.css";
import AppViewStore from "./stores/app.view.store";
import "./theme.scss";

const AdminRoutes = [
    <AuthorizeRoute key="authroute-users" path="/users" component={UserLayout} />,
    <AuthorizeRoute key="authroute-clients" path="/clients" component={ClientLayout} />,
    <AuthorizeRoute key="authroute-client-users" path="/client-users" component={ClientUserLayout} />,
    <AuthorizeRoute key="authroute-expenses" path="/expenses" component={ExpenseLayout} />,
    <AuthorizeRoute key="authroute-tasks" path="/tasks" component={TaskTypeLayout} />,
    <AuthorizeRoute key="authroute-expense-types" path="/expense-types" component={ExpenseTypeLayout} />,
    <AuthorizeRoute key="authroute-time" path="/time" component={TimeEntryLayout} />,
    <AuthorizeRoute key="authroute-bills" path="/bills" component={BillLayout} />,
];

const UserRoutes = [<AuthorizeRoute path="/time" component={TimeEntryLayout} />];

@provider(
    AppViewStore,
    AuthorizedAxios,
    SubscriptionService,
    UserApi,
    UserDataStore,
    UserViewStore,
    ClientApi,
    ClientDataStore,
    ClientViewStore,
    ClientUserApi,
    ClientUserDataStore,
    ClientUserViewStore,
    TaskTypeApi,
    TaskTypeDataStore,
    TaskTypeViewStore,
    ExpenseApi,
    ExpenseDataStore,
    ExpenseViewStore,
    ExpenseClientSelectViewStore,
    ExpenseTypeApi,
    ExpenseTypeDataStore,
    ExpenseTypeViewStore,
    TimeEntryApi,
    TimeEntryDataStore,
    TimeEntryViewStore,
    TimeEntrySurveyStore,
    BillApi,
    BillDataStore,
    BillViewStore,
    BillSelectViewStore,
    BillTimeSelectViewStore,
    BillExpenseSelectViewStore,
    BillTimeImportViewStore,
    BillExpenseImportViewStore,
    BillDiscountSelectViewStore,
    BillClientSelectViewStore,
    AppToastsViewStore
)
@observer
export default class App extends React.Component {
    @inject private viewStore!: AppViewStore;

    constructor(props: {}) {
        super(props);
        Survey.Survey.cssType = "m-0 p-0";
        Survey.StylesManager.applyTheme("default");
        widgets.select2tagbox(Survey);
    }

    componentDidMount() {
        this.viewStore.init();
    }

    componentWillUnmount() {
        this.viewStore.dispose();
    }

    render() {
        const { isInitializing, isLoggedIn, isAdmin } = this.viewStore;

        if (isInitializing) {
            return (
                <div className="d-flex vh-100 align-items-center">
                    <DefaultLoader />
                </div>
            );
        }

        return (
            <React.Fragment>
                <AppToasts />
                <Layout>
                    <Route exact path="/" component={Home} />
                    <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                    {isLoggedIn ? (
                        <React.Fragment>{isAdmin ? AdminRoutes : UserRoutes}</React.Fragment>
                    ) : (
                        <Redirect to="/" />
                    )}
                </Layout>
            </React.Fragment>
        );
    }
}
