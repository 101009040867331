import React from "react";
import { DefaultNavLink } from "../navigation/nav-menu";
import { ApplicationPaths } from "./ApiAuthorizationConstants";
import authService from "./AuthorizeService";

const logoutLink = () => {
    return <DefaultNavLink to={ApplicationPaths.LogOut} text="Logout" />;
};

const loginLink = () => {
    return <DefaultNavLink to={ApplicationPaths.Login} text="Login" />;
};

export class LoginMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isAuthenticated: false, userName: undefined };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
        this.setState({
            isAuthenticated,
            userName: user && user.name,
        });
    }

    render() {
        const { isAuthenticated } = this.state;
        return <React.Fragment>{!isAuthenticated ? loginLink() : logoutLink()}</React.Fragment>;
    }
}
