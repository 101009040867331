import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { Button, Table } from "react-bootstrap";
import { inject } from "react-ioc";
import { GroupBy } from "../common/api-utils";
import DefaultEditPageHeader from "../common/default-edit-page-header";
import DefaultExportRangeModal from "../common/default-export-range-modal";
import DefaultImportModal from "../common/default-import-modal";
import DefaultLoader from "../common/default-loader";
import { HourFormatter } from "../common/formatters";
import DaySelect from "./day-select";
import { TimeEntryDataStore } from "./store/time-entry-data.store";
import { TimeEntrySurveyStore } from "./store/time-entry-survey.store";
import { TimeEntryViewStore } from "./store/time-entry-view.store";
import TimeEntrySelect from "./time-entry-select";

@observer
export default class TimeEntryLayout extends React.Component {
    @inject private dataStore!: TimeEntryDataStore;
    @inject private viewStore!: TimeEntryViewStore;
    @inject private surveyStore!: TimeEntrySurveyStore;

    componentDidMount() {
        this.dataStore.init();
    }

    componentWillUnmount() {
        this.viewStore.setSearch("");
    }

    render() {
        const {
            isInitializing: isViewInitializing,
            showImportModal,
            importing,
            imported,
            validated,
            validating,
            setShowImportModal,
            importFile,
            validateFile,
            filteredItems: items,
            downloadTemplate,
            downloadInRange,
            showExportModal,
            setShowExportModal,
            timeForCalendar,
            setSelectedDays,
            selectedDays,
            lastSelected,
        } = this.viewStore;
        const {
            isInitializing: isSurveyInitializing,
            json,
            editingItem,
            startEditItem,
            saveItem,
            onEditValueChanging,
        } = this.surveyStore;

        const isInitializing = isViewInitializing || isSurveyInitializing;

        return (
            <div>
                <DefaultExportRangeModal
                    title="Export Time in Date Range"
                    formatValue={(value) => HourFormatter.format(value)}
                    show={showExportModal}
                    items={timeForCalendar}
                    onHide={() => setShowExportModal(false)}
                    onExport={(start, end) => downloadInRange(start, end)}
                />
                <DefaultImportModal
                    show={showImportModal}
                    onSetShow={setShowImportModal}
                    title={"Time"}
                    importing={importing}
                    imported={imported}
                    validating={validating}
                    validated={validated}
                    onDownloadTemplateClick={downloadTemplate}
                    onImportClick={importFile}
                    onValidateClick={validateFile}
                />
                <DefaultEditPageHeader
                    isPlural
                    title="Time"
                    description="View, edit, and record time.  Select a date on the calendar to get started."
                    json={json}
                    editingItem={editingItem}
                    onStartEdit={startEditItem}
                    onSave={saveItem}
                    onValueChanging={onEditValueChanging}
                />
                <div className="d-flex align-content-top text-nowrap">
                    <div className="m-0 d-flex flex-column pr-5 py-0">
                        <DaySelect />
                        <div className="d-flex my-2" style={{ gap: 15 }}>
                            <Button
                                disabled={!lastSelected}
                                variant="primary-outline"
                                size="sm"
                                className="w-50 rounded-0"
                                onClick={() => {
                                    if (lastSelected === undefined) {
                                        return;
                                    }

                                    const lastDayOfMonth = new Date(
                                        lastSelected.getFullYear(),
                                        lastSelected.getMonth() + 1,
                                        0
                                    );
                                    const days = [lastDayOfMonth];
                                    let day = lastDayOfMonth.getDate();
                                    while (day > 1) {
                                        days.push(new Date(lastSelected.getFullYear(), lastSelected.getMonth(), --day));
                                    }
                                    setSelectedDays(days);
                                }}
                            >
                                Select Month
                                {lastSelected === undefined
                                    ? undefined
                                    : ` of ${lastSelected.toLocaleString("default", { month: "long" })}`}
                            </Button>
                            <Button
                                disabled={!selectedDays?.length}
                                variant="secondary-outline"
                                size="sm"
                                className="w-50 rounded-0"
                                onClick={() => setSelectedDays([])}
                            >
                                Deselect All
                            </Button>
                        </div>
                        <Table borderless>
                            <tbody>
                                {Array.from(
                                    GroupBy(
                                        items.sort((a, b) => (a.date > b.date ? 1 : -1)),
                                        (i) => i.date
                                    )
                                ).map((x) => {
                                    const hours = x[1].reduce((sum, curr) => sum + curr.hours, 0);
                                    return (
                                        <tr
                                            key={`time-entry-${x[0]}`}
                                            className={classNames(
                                                "time-summary border-3 border-right-0 border-top-0 border-bottom-0 ",
                                                {
                                                    "border-success": hours >= 8,
                                                    "border-warning": hours < 8,
                                                    "border-danger": hours < 4,
                                                }
                                            )}
                                        >
                                            <td className="w-25 border-top-0 border-bottom">{x[0]}</td>
                                            <td className="w-25 border-top-0 border-bottom text-right">
                                                {hours.toFixed(2)}
                                            </td>
                                            <td className="w-50 border-top-0 border-bottom pl-0">hours</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <div className="m-0 p-0 w-100 text-truncate">
                        <div
                            style={{
                                minWidth: "10rem",
                            }}
                        >
                            <TimeEntrySelect />
                        </div>
                    </div>
                </div>
                {isInitializing ? <DefaultLoader className="mt-4" /> : undefined}
            </div>
        );
    }
}
