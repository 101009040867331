import classNames from "classnames";
import React from "react";
import { Table } from "react-bootstrap";
import DefaultEditPageHeader, { DefaultEditPageHeaderProps } from "./default-edit-page-header";
import DefaultLoader from "./default-loader";
import SearchBar from "./search-bar";

interface Props<TItem> extends DefaultEditPageHeaderProps<TItem> {
    items: TItem[];
    isInitializing?: boolean;
    editingItem?: TItem;

    search: string;
    onMatchesSearch: (item: TItem, search: string) => boolean;
    onSetSearch: (value: string) => void;
    onAddButtonClick?: () => void;

    tableHeader: JSX.Element;
    tableCells: (props: { item: TItem }) => JSX.Element;
}

const DefaultEditPage = <TItem extends { id: number | string; name: string }>(props: Props<TItem>) => {
    return (
        <div>
            <DefaultEditPageHeader {...props} />
            <SearchBar search={props.search} setSearch={props.onSetSearch} onAddButtonClick={props.onAddButtonClick} />
            <Table borderless hover striped className="user-select-none">
                {props.tableHeader}
                {!props.isInitializing ? (
                    <tbody className="cursor-pointer">
                        {props.items
                            .filter((x) => props.onMatchesSearch(x, props.search))
                            .map((item) => (
                                <tr
                                    key={`tr-${item.id}`}
                                    onClick={() => {
                                        props.onStartEdit(item);
                                    }}
                                    className={classNames({ "bg-primary": item.id === props.editingItem?.id })}
                                >
                                    <props.tableCells item={item} />
                                </tr>
                            ))}
                    </tbody>
                ) : undefined}
            </Table>
            {props.isInitializing ? <DefaultLoader className="mt-4" /> : undefined}
        </div>
    );
};

export default DefaultEditPage;
