import { faLock, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import ClientSelect, { ClientSelectProps } from "../../common/client-select";
import DefaultLoader from "../../common/default-loader";
import BillAccordionItem from "./bill-accordion-item";
import BillSelect from "./bill-select";
import { Bill } from "../store/bill-data.store";
import { AccordionItem } from "../store/bill-view.store";

interface Props extends ClientSelectProps {
    selectedItem: AccordionItem;
    selectedBill?: Bill;
    setSelectedItem: (item: AccordionItem) => void;
}

const BillAccordionItemBillSelect = (props: Props) => {
    return (
        <BillAccordionItem
            className="mt-3"
            title="Bill Selection"
            description="Please choose a bill to update. Begin by selecting a client, then either select a bill or add a new one."
            itemType="bill-select"
            selectedItemType={props.selectedItem}
            showEdit={props.selectedItem !== "bill-select"}
            showSummary={props.selectedItem === undefined || props.selectedItem !== "bill-select"}
            onEdit={props.setSelectedItem}
            headerSummary={
                <div className="w-100 d-flex mt-2">
                    <div className="w-50">
                        <p className="mb-1 text-subtle">Client Name</p>
                        <p className="m-0">{props.selectedClient?.text}</p>
                    </div>
                    <div className="w-50">
                        <p className="mb-1 text-subtle d-flex">
                            Bill
                            {props.selectedBill?.locked ? (
                                <p className="pl-2 m-0">
                                    <FontAwesomeIcon icon={faLock} />
                                </p>
                            ) : undefined}
                        </p>
                        <p className="d-flex m-0">
                            {props.selectedBill?.coverageStartDate}
                            <p className="px-2">
                                <FontAwesomeIcon icon={faLongArrowAltRight} />
                            </p>
                            {props.selectedBill?.coverageEndDate}
                        </p>
                    </div>
                </div>
            }
            body={
                <React.Fragment>
                    <div className="d-flex align-content-top text-nowrap">
                        <div
                            className={classNames("m-0 animate-width", {
                                "w-50 pl-0 py-0": props.selectedClient,
                                "w-100 p-0": !props.selectedClient,
                            })}
                        >
                            <ClientSelect {...props} />
                        </div>
                        <div
                            className={classNames("m-0 py-0 pl-2 pr-0 animate-width w-100", {
                                "hide-panel": !props.selectedClient,
                            })}
                        >
                            <div
                                style={{
                                    minWidth: "10rem",
                                }}
                            >
                                <BillSelect />
                            </div>
                        </div>
                    </div>
                    {props.isInitializing ? <DefaultLoader className="mt-4" /> : undefined}
                </React.Fragment>
            }
        />
    );
};

export default BillAccordionItemBillSelect;
