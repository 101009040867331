import { action, makeObservable, observable, runInAction } from "mobx";
import { inject } from "react-ioc";
import { DropdownChoice } from "../../common/api-utils";
import SubscriptionService, { SubscriptionsTypes } from "../../common/subscription-service";
import { ClientUser, ClientUserDataStore } from "./client-user-data.store";

const GenerateJson = (
    userOptions: DropdownChoice[],
    clientOptions: DropdownChoice[],
    classOptions: DropdownChoice[],
    addForm: boolean
) => {
    const elements: any[] = [
        {
            name: "name",
            type: "text",
            title: "What name would you like to appear on the report?",
            isRequired: true,
        },
        {
            name: "title",
            type: "text",
            title: "What title would you like to appear on the report?",
        },
        {
            name: "rate",
            type: "text",
            title: "What is their charge rate?",
            inputType: "number",
            min: 0,
        },
        {
            name: "cap",
            type: "text",
            title: "What is their cap?",
            inputType: "number",
            min: 0,
        },
        {
            name: "class",
            type: "dropdown",
            title: "What is their classification?",
            choices: classOptions,
        },
    ];

    if (addForm) {
        elements.splice(0, 0, {
            name: "clientId",
            type: "dropdown",
            title: "Which client will this user be assigned to?",
            isRequired: true,
            choices: clientOptions,
        });

        elements.splice(0, 0, {
            name: "userId",
            type: "dropdown",
            title: "Which user will this client be assigned to?",
            isRequired: true,
            choices: userOptions,
        });
    }

    return {
        elements: elements,
        showQuestionNumbers: "off",
        completedHtml: "<p>Updating...</p>",
        completeText: "Save",
    };
};

export type Client = {
    value: number;
    text: string;
};

export class ClientUserViewStore {
    @inject private dataStore!: ClientUserDataStore;
    @inject private subscriptionService!: SubscriptionService;

    isInitializing?: boolean;
    items: ClientUser[] = [];
    editingItem?: ClientUser;
    search = "";

    clients: Client[] = [];
    selectedClient?: Client;
    clientSearch = "";
    private editClientUserJson: any = {};
    private addClientUserJson: any = {};
    private isCreating = false;

    constructor() {
        makeObservable(this, {
            isInitializing: observable,
            items: observable,
            editingItem: observable,
            search: observable,
            clients: observable,
            selectedClient: observable,
            clientSearch: observable,
            init: action,
            startEditItem: action,
            startAddItem: action,
            setSearch: action,
            setSelectedClient: action,
        });
    }

    init = async () => {
        if (this.isInitializing !== undefined) {
            return;
        }

        try {
            await this.initInteral();
        } catch (e) {
            runInAction(() => (this.isInitializing = undefined));
            throw e;
        } finally {
            this.subscriptionService.subscribe(
                [SubscriptionsTypes.UserList, SubscriptionsTypes.ClientList],
                this.initInteral
            );
        }
    };

    private initInteral = async () => {
        this.isInitializing = true;
        const data = await this.dataStore.get();

        this.editClientUserJson = GenerateJson(data.users, data.clients, data.classTypes, false);
        this.addClientUserJson = GenerateJson(data.users, data.clients, data.classTypes, true);

        runInAction(() => {
            this.items = data.clientUsers;
            this.clients = data.clients as Client[];
            this.isInitializing = false;
        });
    };

    startEditItem = (clientUser?: ClientUser) => {
        this.editingItem = clientUser;
        this.isCreating = false;
    };

    startAddItem = () => {
        this.editingItem = { id: 0, name: "", clientId: this.selectedClient?.value ?? 0, userId: 0, defaultTitle: "" };
        this.isCreating = true;
    };

    saveItem = async (clientUser: ClientUser) => {
        const index = this.isCreating
            ? this.items.findIndex((x) => x.clientId === clientUser.clientId && x.userId === clientUser.userId)
            : this.items.findIndex((x) => x.id === clientUser.id);

        try {
            const updated = this.isCreating
                ? await this.dataStore.add(clientUser)
                : await this.dataStore.edit(clientUser);

            runInAction(() => {
                if (~index) {
                    this.items.splice(index, 1, updated);
                } else {
                    this.items.push(updated);
                }
                this.startEditItem(undefined);
            });
        } catch (e) {
            runInAction(() => {
                this.startEditItem(undefined);
            });
            throw e;
        }

        this.subscriptionService.publish(SubscriptionsTypes.ClientUserList);
    };

    setSearch = (value: string) => {
        this.search = value.toLowerCase();
    };

    setSelectedClient = (value?: Client) =>
        (this.selectedClient = value?.value === this.selectedClient?.value ? undefined : value);

    setClientSearch = (value: string) => {
        this.clientSearch = value.toLowerCase();
    };

    getClientName = (id: number) => this.clients.find((x) => x.value === id)?.text;

    get json() {
        return this.isCreating ? this.addClientUserJson : this.editClientUserJson;
    }
}
