import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { Alert, Button, Table } from "react-bootstrap";
import { useInstance } from "react-ioc";
import { ClientUser } from "../common/api-utils";
import SearchBar from "../common/search-bar";
import { TimeEntry } from "./store/time-entry-data.store";
import { TimeEntrySurveyStore } from "./store/time-entry-survey.store";
import { TimeEntryViewStore } from "./store/time-entry-view.store";
import TimeEntryFilterModal from "./time-entry-filter-modal";

const TableHeader = (
    <thead className="user-select-none">
        <tr>
            {["Date", "User", "Client", "Hours", "Description"].map((x) => (
                <th key={`time_header-${x}`} className="border-top-0">
                    {x}
                </th>
            ))}
        </tr>
    </thead>
);

const EditableTableCells = (props: { item: TimeEntry & { clientUser?: ClientUser } }) => {
    return (
        <React.Fragment>
            <td hidden>{props.item.id}</td>
            <td>{props.item.date}</td>
            <td>{props.item.clientUser?.text}</td>
            <td>{props.item.clientUser?.clientName}</td>
            <td>{props.item.hours}</td>
            <td className="text-truncate text-nowrap">{props.item.narrative}</td>
        </React.Fragment>
    );
};

const MatchesSearch = (time: TimeEntry & { clientUser?: ClientUser }, search: string) => {
    return (
        !search ||
        `${time.narrative}${time.clientUser?.clientName}${time.clientUser?.text}${time.date}`
            .toLowerCase()
            .includes(search)
    );
};

const TimeEntrySelect = observer(() => {
    const {
        isInitializing,
        items,
        filteredItems,
        search,
        setSearch,
        setShowImportModal,
        setShowExportModal,
        setShowFilterModal,
        filter,
        showFilterModal,
        filterJson,
        setFilter,
    } = useInstance(TimeEntryViewStore);
    const { startAddItem, startEditItem, editingItem } = useInstance(TimeEntrySurveyStore);

    return (
        <React.Fragment>
            <TimeEntryFilterModal
                show={showFilterModal}
                filter={filter}
                json={filterJson}
                onHide={() => setShowFilterModal(false)}
                onSave={setFilter}
            />
            <SearchBar
                fullWidth
                search={search}
                setSearch={setSearch}
                onAddButtonClick={startAddItem}
                onExportButtonClick={() => setShowExportModal(true)}
                onImportButtonClick={() => setShowImportModal(true)}
                filterProps={{
                    exists: !!filter,
                    onButtonClick: () => setShowFilterModal(true),
                }}
            />
            <Alert show={!!filter} dismissible={false} variant="info" className="my-3">
                <Alert.Heading>Filtering Enabled</Alert.Heading>
                These time records are currently being filtered. Currently, the list below is hiding{" "}
                <b>{filteredItems.length !== items.length ? items.length - filteredItems.length : "no"}</b> records.
                {!!filter ? (
                    <div className="d-flex flex-column mt-2">
                        Filters currently applied:
                        {filter.clientName ? (
                            <span className="ml-3">
                                Client: <b>{filter.clientName}</b>
                            </span>
                        ) : undefined}
                        {filter.userName ? (
                            <span className="ml-3">
                                User: <b>{filter.userName}</b>
                            </span>
                        ) : undefined}
                    </div>
                ) : undefined}
                <hr />
                <div className="d-flex justify-content-end">
                    <Button onClick={() => setFilter()} variant="outline-info">
                        Clear Filters
                    </Button>
                </div>
            </Alert>
            <Table borderless hover striped className="user-select-none">
                {TableHeader}
                {!isInitializing ? (
                    <tbody className="cursor-pointer">
                        {filteredItems
                            .filter((x) => MatchesSearch(x, search))
                            .map((item) => (
                                <tr
                                    key={`time-entry-select-${item.id}`}
                                    onClick={() => {
                                        startEditItem(item);
                                    }}
                                    className={classNames({
                                        "bg-primary": item.id === editingItem?.id,
                                    })}
                                >
                                    <EditableTableCells item={item} />
                                </tr>
                            ))}
                    </tbody>
                ) : undefined}
            </Table>
        </React.Fragment>
    );
});

export default TimeEntrySelect;
