import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { Table } from "react-bootstrap";
import { useInstance } from "react-ioc";
import { ClientUser } from "../common/api-utils";
import { DollarFormatter } from "../common/formatters";
import SearchBar from "../common/search-bar";
import { ExpenseClientSelectViewStore } from "./store/expense-client-select-view.store";
import { Expense } from "./store/expense-data.store";
import { ExpenseViewStore } from "./store/expense-view.store";

const TableHeader = (
    <thead className="user-select-none">
        <tr>
            {["User", "Date", "Description", "Amount"].map((x) => (
                <th key={`expense_header-${x}`} className="border-top-0">
                    {x}
                </th>
            ))}
        </tr>
    </thead>
);

const EditableTableCells = (props: { item: Expense & { clientUser?: ClientUser } }) => {
    return (
        <React.Fragment>
            <td hidden>{props.item.id}</td>
            <td>{props.item.clientUser?.text}</td>
            <td>{props.item.date}</td>
            <td className="text-truncate text-nowrap">{props.item.description}</td>
            <td>{DollarFormatter.format(props.item?.amount ?? 0)}</td>
        </React.Fragment>
    );
};

const MatchesSearch = (expense: Expense & { clientUser?: ClientUser }, search: string) => {
    return (
        !search ||
        `${expense.description}${expense.clientUser?.clientName}${expense.amount}`.toLowerCase().includes(search)
    );
};

const ExpenseSelect = observer(() => {
    const {
        isInitializing,
        sortedItems: items,
        search,
        editingItem,
        setSearch,
        startEditItem,
        startAddItem,
    } = useInstance(ExpenseViewStore);
    const { selected } = useInstance(ExpenseClientSelectViewStore);

    return (
        <React.Fragment>
            <SearchBar fullWidth search={search} setSearch={setSearch} onAddButtonClick={startAddItem} />
            <Table borderless hover striped className="user-select-none">
                {TableHeader}
                {!isInitializing ? (
                    <tbody className="cursor-pointer">
                        {items
                            .filter((x) => x.clientUser?.clientId === selected?.value && MatchesSearch(x, search))
                            .map((item) => (
                                <tr
                                    onClick={() => {
                                        startEditItem(item);
                                    }}
                                    className={classNames({ "bg-primary": item.id === editingItem?.id })}
                                >
                                    <EditableTableCells item={item} />
                                </tr>
                            ))}
                    </tbody>
                ) : undefined}
            </Table>
        </React.Fragment>
    );
});

export default ExpenseSelect;
