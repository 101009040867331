const DollarFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

const HourFormatter = new Intl.NumberFormat("en-US", {
    style: "unit",
    unit: "hour",
    unitDisplay: "long",
} as any);

export { DollarFormatter, HourFormatter };
