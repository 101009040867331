import { action, makeObservable, observable } from "mobx";
import { Expense } from "./bill-data.store";

export class BillExpenseSelectViewStore {
    selectedTimeEntries: number[] = [];
    search = "";

    constructor() {
        makeObservable(this, {
            search: observable,
            selectedTimeEntries: observable,
            setSearch: action,
            toggleSelectedItem: action,
        });
    }

    setSearch = (value: string) => {
        this.search = value.toLowerCase();
    };

    matchesSearch = (value: Expense) => {
        return (
            !this.search ||
            `${value.description}${value.date}${value.userName}${value.amount}${value.expenseTypeName}`
                .toLowerCase()
                .includes(this.search)
        );
    };

    toggleSelectedItem = (value: Expense) => {
        const index = this.selectedTimeEntries.findIndex((x) => x === value.id);
        if (~index) {
            this.selectedTimeEntries.splice(index, 1);
        } else {
            this.selectedTimeEntries.push(value.id);
        }
    };
}
