import { action, makeObservable, observable, runInAction } from "mobx";
import { inject } from "react-ioc";
import SubscriptionService, { SubscriptionsTypes } from "../../common/subscription-service";
import { Bill, BillDataStore } from "./bill-data.store";

export class BillTimeImportViewStore {
    @inject private dataStore!: BillDataStore;
    @inject private subscriptionService!: SubscriptionService;

    showImportModal = false;
    importing = false;
    imported = false;
    validating = false;
    validated = false;

    constructor() {
        makeObservable(this, {
            showImportModal: observable,
            importing: observable,
            imported: observable,
            validating: observable,
            validated: observable,
            updateFile: action,
            validateFile: action,
            setShowImportModal: action,
        });
    }

    setShowImportModal = (value: boolean) => {
        this.showImportModal = value;
        if (!value && !this.validating && !this.importing) {
            this.validated = this.imported = false;
        }
    };

    updateFile = async (bill: Bill, file: any) => {
        this.importing = true;
        this.imported = false;

        let errors: string[] = [];
        try {
            errors = await this.dataStore.updateTime(bill, file);
        } catch {
            errors = ["File upload failed. Please reselect the file and try again."];
        }

        runInAction(() => {
            this.importing = false;
            if (!errors.length) {
                this.imported = true;
            }
        });

        this.subscriptionService.publish(SubscriptionsTypes.TimeList);

        return errors;
    };

    validateFile = async (bill: Bill, file: any) => {
        this.validating = true;
        this.validated = false;

        let errors: string[] = [];
        try {
            errors = await this.dataStore.validateTime(bill, file);
        } catch {
            errors = ["File upload failed. Please reselect the file and try again."];
        }

        runInAction(() => {
            this.validating = false;
            if (!errors.length) {
                this.validated = true;
            }
        });
        return errors;
    };

    exportTimeFile = (bill: Bill) => this.dataStore.exportTime(bill);
}
