import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { inject } from "react-ioc";
import ClientSelect from "../common/client-select";
import DefaultEditPageHeader from "../common/default-edit-page-header";
import DefaultLoader from "../common/default-loader";
import ClientUserSelect from "./client-user-select";
import "./client-user.scss";
import { ClientUserViewStore } from "./store/client-user-view.store";

@observer
export default class ClientUserLayout extends React.Component {
    @inject private viewStore!: ClientUserViewStore;

    componentDidMount() {
        this.viewStore.init();
    }

    componentWillUnmount() {
        this.viewStore.setSearch("");
    }

    render() {
        const {
            isInitializing,
            editingItem,
            json,
            startEditItem,
            saveItem,
            selectedClient,
            clients,
            clientSearch,
            setClientSearch,
            setSelectedClient,
        } = this.viewStore;

        return (
            <div>
                <DefaultEditPageHeader
                    title="Client User"
                    description="View, edit, add, and assign users to clients.  Select a client to get started."
                    json={json}
                    editingItem={editingItem}
                    onStartEdit={startEditItem}
                    onSave={saveItem}
                />
                <div className="d-flex align-content-top text-nowrap">
                    <div
                        className={classNames("m-0 animate-width", {
                            "w-50 pl-0 py-0 pr-2": selectedClient,
                            "w-100 p-0": !selectedClient,
                        })}
                    >
                        <ClientSelect
                            clients={clients}
                            clientSearch={clientSearch}
                            isInitializing={isInitializing}
                            selectedClient={selectedClient}
                            onSetClientSelected={setSelectedClient}
                            onClientSearch={setClientSearch}
                        />
                    </div>
                    <div
                        className={classNames("m-0 p-0 animate-width w-100", {
                            "hide-panel": !selectedClient,
                        })}
                    >
                        <div
                            style={{
                                minWidth: "10rem",
                            }}
                        >
                            <ClientUserSelect />
                        </div>
                    </div>
                </div>
                {isInitializing ? <DefaultLoader className="mt-4" /> : undefined}
            </div>
        );
    }
}
