import { makeObservable, observable, runInAction } from "mobx";
import { inject } from "react-ioc";
import {
    ClientUserBasicApiModel,
    ConvertFromApiDate,
    ConvertToDate,
    CreateDropdownChoices,
    Distinct,
    DropdownChoice,
    SortChoices,
} from "../../common/api-utils";
import BillApi, { BillApiModel, DiscountApiModel } from "../api/bill.api";

export type Bill = {
    id: number;
    clientId: number;
    locked: boolean;

    billedDate?: string;
    coverageStartDate: string;
    coverageEndDate: string;

    timeIds: number[];
    expenseIds: number[];

    discounts: Discount[];

    totalCap: number;
};
export type Discount = DiscountApiModel;
export type Time = {
    id: number;
    client: string;
    clientId: number;
    userName: string;

    taskTypeId: number;
    taskTypeName?: string;

    hours: number;
    date: string;
    narrative: string;

    location?: number;
};
export type Expense = {
    id: number;
    active: boolean;

    client: string;
    clientId: number;
    userName: string;

    expenseTypeId: number;
    expenseTypeName?: string;

    participantUserIds?: number[];

    date: string;
    expenseMonth?: Date;
    billedMonth?: Date;

    amount: number;
    location?: number;

    description: string;
};
export type Client = {
    value: number;
    text: string;
};

export class BillDataStore {
    @inject private api!: BillApi;
    private discountList: Discount[] = [];
    discountTypeOptions: DropdownChoice[] = [];

    constructor() {
        makeObservable(this, {
            discountTypeOptions: observable,
        });
    }

    get = async (): Promise<{
        bills: Bill[];
        time: Time[];
        expenses: Expense[];
        clients: Client[];
    }> => {
        const { data } = await this.api.get();
        this.discountList = data.discounts;
        runInAction(() => {
            this.discountTypeOptions = CreateDropdownChoices(data.discountTypes.filter((x) => x.id !== 0));
        });

        return {
            ...data,
            time: data.time
                .sort((a, b) => (a.date > b.date ? 1 : a.date === b.date ? 0 : -1))
                .map((x) => {
                    const userData = this.parseClientUser(x, data.clientUsers);
                    const taskData = data.tasks.find((y) => y.id === x.taskTypeId);
                    return {
                        ...x,
                        ...userData,
                        date: ConvertFromApiDate(x.date)!,
                        taskTypeName: taskData?.name,
                    };
                }),
            expenses: data.expenses
                .sort((a, b) => (a.date > b.date ? 1 : a.date === b.date ? 0 : -1))
                .map((x) => {
                    const userData = this.parseClientUser(x, data.clientUsers);
                    const type = data.expenseTypes.find((y) => y.id === x.expenseTypeId);
                    return {
                        ...x,
                        ...userData,
                        date: ConvertFromApiDate(x.date)!,
                        expenseTypeName: type?.name,
                    };
                }),
            bills: data.bills.map((x) => this.createBill(x)),
            clients: SortChoices(
                CreateDropdownChoices(
                    Distinct(
                        data.clientUsers.map((x) => {
                            return {
                                id: x.clientId,
                                name: x.clientName,
                            };
                        })
                    ).filter((item, index, self) => self.indexOf(item) === index)
                ).map((x) => {
                    return {
                        ...x,
                        value: x.value as number,
                    };
                })
            ),
        };
    };

    edit = async (bill: Bill): Promise<Bill> => {
        const { data } = await this.api.edit(this.createApiModel(bill));
        return this.createBill(data);
    };

    add = async (bill: Bill): Promise<Bill> => {
        const { data } = await this.api.add(this.createApiModel(bill));
        return this.createBill(data);
    };

    updateTime = async (bill: Bill, file: any) => {
        const { data } = await this.api.updateTime(bill, {
            fileName: file.name,
            formFile: file,
        });
        return data;
    };

    validateTime = async (bill: Bill, file: any) => {
        const { data } = await this.api.validateTime(bill, {
            fileName: file.name,
            formFile: file,
        });
        return data;
    };

    exportTime = (bill: Bill) => this.api.exportTime(bill.id);

    updateExpenses = async (bill: Bill, file: any) => {
        const { data } = await this.api.updateExpenses(bill, {
            fileName: file.name,
            formFile: file,
        });
        return data;
    };

    validateExpenses = async (bill: Bill, file: any) => {
        const { data } = await this.api.validateExpenses(bill, {
            fileName: file.name,
            formFile: file,
        });
        return data;
    };

    exportExpenses = (bill: Bill) => this.api.exportExpenses(bill.id);

    generateReport = (bill: Bill) => this.api.generateReport(bill);

    updateBillDiscount = async (discount: Discount) => {
        const { data } = await this.api.updateBillDiscount(discount);
        return data;
    };

    private createApiModel = (bill: Bill): BillApiModel => {
        return {
            id: bill.id,
            clientId: bill.clientId,
            locked: bill.locked,
            timeIds: bill.timeIds,
            expenseIds: bill.expenseIds,
            discountIds: bill.discounts?.map((x) => x.id) ?? [],

            billedDate: ConvertToDate(bill.billedDate),
            coverageStartDate: ConvertToDate(bill.coverageStartDate)!,
            coverageEndDate: ConvertToDate(bill.coverageEndDate)!,

            totalCap: bill.totalCap,
        };
    };

    private createBill = (model: BillApiModel): Bill => {
        return {
            ...model,
            discounts: model.discountIds?.length
                ? this.discountList.filter((y) => model.discountIds.includes(y.id))
                : [],
            billedDate: ConvertFromApiDate(model.billedDate),
            coverageEndDate: ConvertFromApiDate(model.coverageEndDate)!,
            coverageStartDate: ConvertFromApiDate(model.coverageStartDate)!,
        };
    };

    private parseClientUser = (
        model: {
            clientUserId: number;
        },
        clientUsers: ClientUserBasicApiModel[]
    ): { client: string; clientId: number; userName: string } => {
        const clientUser = clientUsers.find((x) => x.id === model.clientUserId);
        if (!clientUser) {
            throw new Error("Could not find client user with matching id.");
        }

        return {
            client: clientUser.clientName,
            clientId: clientUser.clientId,
            userName: clientUser.name,
        };
    };
}
