import { inject } from "react-ioc";
import TaskTypeApi, { TaskTypeApiModel } from "../api/tasktype.api";

export type TaskType = TaskTypeApiModel;

const getSortOrderAsInt = (sortOrder?: string) => {
    const cast = !!sortOrder?.length ? Number(sortOrder) : undefined;
    return cast && cast >= 0 ? cast : undefined;
};

export class TaskTypeDataStore {
    @inject private api!: TaskTypeApi;

    get = async (): Promise<TaskType[]> => {
        const { data } = await this.api.get();
        return data;
    };

    edit = async (model: TaskType) => {
        const { data } = await this.api.edit({
            ...model,
            sortOrder: getSortOrderAsInt(model.sortOrder?.toString()),
        });
        return data;
    };

    add = async (model: TaskType) => {
        const { data } = await this.api.add({
            ...model,
            sortOrder: getSortOrderAsInt(model.sortOrder?.toString()),
        });
        return data;
    };
}
