import { observer } from "mobx-react";
import React from "react";
import { inject } from "react-ioc";
import DefaultEditPage from "../common/default-edit-page";
import { Client } from "./store/client-data.store";
import { ClientViewStore } from "./store/client-view.store";

const TableHeader = (
    <thead className="user-select-none">
        <tr>
            {["Reporting Name"].map((x) => (
                <th key={`client_row-${x}`} className="border-top-0">
                    {x}
                </th>
            ))}
        </tr>
    </thead>
);

const EditableTableCells = (props: { item: Client }) => {
    return (
        <React.Fragment>
            <td hidden>{props.item.id}</td>
            <td>{props.item.displayName}</td>
        </React.Fragment>
    );
};

@observer
export default class ClientLayout extends React.Component {
    @inject private viewStore!: ClientViewStore;

    componentDidMount() {
        this.viewStore.init();
    }

    componentWillUnmount() {
        this.viewStore.setSearch("");
    }

    render() {
        const {
            isInitializing,
            items,
            editingItem,
            search,
            editClientJson,
            startEditItem,
            startAddItem,
            saveItem,
            setSearch,
        } = this.viewStore;

        return (
            <DefaultEditPage
                title="Client"
                description="View, edit, and add new clients."
                json={editClientJson}
                editingItem={editingItem}
                onStartEdit={startEditItem}
                onAddButtonClick={startAddItem}
                onSave={saveItem}
                items={items}
                isInitializing={isInitializing}
                search={search}
                onSetSearch={setSearch}
                onMatchesSearch={(x, s) => !s || x.displayName.toLowerCase().includes(s)}
                tableHeader={TableHeader}
                tableCells={EditableTableCells}
            />
        );
    }
}
