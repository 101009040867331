import { AxiosResponse } from "axios";
import { inject } from "react-ioc";
import {
    ClientUserBasicApiModel,
    DownloadFileFromResponse,
    FileApiModel,
    NamedIdApiModel,
} from "../../common/api-utils";
import AuthorizedAxios from "../../common/authorized-axios";
import { ExpenseApiModel } from "../../expense/api/expense.api";
import { TimeEntryApiModel } from "../../time/api/time-entry.api";

class BillApi {
    private controller = "billapi";
    @inject axios!: AuthorizedAxios;

    get = async (): Promise<AxiosResponse<BillFormApiModel>> => {
        return (await this.axios.createInstance()).get(`${this.controller}/get`);
    };

    add = async (model: EditBillApiModel): Promise<AxiosResponse<BillApiModel>> => {
        return (await this.axios.createInstance()).post(`${this.controller}/add`, model, {
            responseType: "json",
        });
    };

    edit = async (model: EditBillApiModel): Promise<AxiosResponse<BillApiModel>> => {
        return (await this.axios.createInstance()).post(`${this.controller}/edit`, model, {
            responseType: "json",
        });
    };

    updateBillDiscount = async (model: EditDiscountApiModel): Promise<AxiosResponse<DiscountApiModel>> => {
        return (await this.axios.createInstance()).post(`${this.controller}/updateBillDiscount`, model, {
            responseType: "json",
        });
    };

    generateReport = async (bill: { id: number }): Promise<void> => {
        const response = await (
            await this.axios.createInstance()
        ).get<any>(`${this.controller}/generateReport?billId=${bill.id}`, {
            responseType: "blob",
        });

        DownloadFileFromResponse(response);
    };

    updateTime = async (bill: { id: number }, model: FileApiModel): Promise<AxiosResponse<string[]>> => {
        const form = new FormData();
        form.append("fileName", model.fileName);
        form.append("formFile", model.formFile);

        return (await this.axios.createInstance()).post(`${this.controller}/updateTime?billId=${bill.id}`, form, {
            responseType: "json",
        });
    };

    validateTime = async (bill: { id: number }, model: FileApiModel): Promise<AxiosResponse<string[]>> => {
        const form = new FormData();
        form.append("fileName", model.fileName);
        form.append("formFile", model.formFile);

        return (await this.axios.createInstance()).post(`${this.controller}/validateTime?billId=${bill.id}`, form, {
            responseType: "json",
        });
    };

    exportTime = async (billId: number): Promise<void> => {
        const response = await (
            await this.axios.createInstance()
        ).get<any>(`${this.controller}/exportTime?billId=${billId}`, {
            responseType: "blob",
        });

        DownloadFileFromResponse(response);
    };

    updateExpenses = async (bill: { id: number }, model: FileApiModel): Promise<AxiosResponse<string[]>> => {
        const form = new FormData();
        form.append("fileName", model.fileName);
        form.append("formFile", model.formFile);

        return (await this.axios.createInstance()).post(`${this.controller}/updateExpenses?billId=${bill.id}`, form, {
            responseType: "json",
        });
    };

    validateExpenses = async (bill: { id: number }, model: FileApiModel): Promise<AxiosResponse<string[]>> => {
        const form = new FormData();
        form.append("fileName", model.fileName);
        form.append("formFile", model.formFile);

        return (await this.axios.createInstance()).post(`${this.controller}/validateExpenses?billId=${bill.id}`, form, {
            responseType: "json",
        });
    };

    exportExpenses = async (billId: number): Promise<void> => {
        const response = await (
            await this.axios.createInstance()
        ).get<any>(`${this.controller}/exportExpenses?billId=${billId}`, {
            responseType: "blob",
        });

        DownloadFileFromResponse(response);
    };
}

interface BillFormApiModel {
    bills: BillApiModel[];
    tasks: NamedIdApiModel[];
    expenseTypes: NamedIdApiModel[];
    discounts: DiscountApiModel[];
    discountTypes: NamedIdApiModel[];

    time: TimeEntryApiModel[];
    expenses: ExpenseApiModel[];

    clientUsers: ClientUserBasicApiModel[];
}

export interface BillApiModel extends EditBillApiModel {}

interface EditBillApiModel {
    id: number;
    clientId: number;
    locked: boolean;

    billedDate?: Date;
    coverageStartDate: Date;
    coverageEndDate: Date;

    timeIds: number[];
    expenseIds: number[];
    discountIds: number[];

    totalCap: number;
}

export interface DiscountApiModel extends EditDiscountApiModel {}

interface EditDiscountApiModel {
    id: number;
    billId: number;
    name: string;
    amount: number;
    calculationType: CalculationType;
    applyLast: boolean;
}

export enum CalculationType {
    None = 0,
    Travel = 1,
    Courtesy = 100,
}

export default BillApi;
