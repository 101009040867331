import { observer } from "mobx-react";
import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { useInstance } from "react-ioc";
import AppToastsViewStore from "./store/app-toasts-view.store";
import "./app-toasts.scss";

const AppToasts = observer(() => {
    const { toasts, removeToast } = useInstance(AppToastsViewStore);
    return (
        <ToastContainer className="app-toast-container mt-2 mr-2">
            {!toasts?.length
                ? undefined
                : toasts.map((x) => (
                      <Toast onClose={() => removeToast(x)} bg="light">
                          <Toast.Header>
                              <strong className="me-auto text-danger">Error</strong>
                          </Toast.Header>
                          <Toast.Body> {x}</Toast.Body>
                      </Toast>
                  ))}
        </ToastContainer>
    );
});

export default AppToasts;
