import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { Accordion, Button, Spinner } from "react-bootstrap";
import { inject } from "react-ioc";
import DefaultImportModal from "../common/default-import-modal";
import DefaultPageHeader from "../common/default-page-header";
import "./bill-layout.scss";
import BillAccordionItemBillDetails from "./components/bill-accordion-item-bill-details";
import BillAccordionItemBillSelect from "./components/bill-accordion-item-bill-select";
import BillAccordionItemDiscountSelect from "./components/bill-accordion-item-discount-select";
import BillAccordionItemExpenseSelect from "./components/bill-accordion-item-expense-select";
import BillAccordionItemTimeSelect from "./components/bill-accordion-item-time-select";
import { BillClientSelectViewStore } from "./store/bill-client-select-view.store";
import { BillExpenseImportViewStore } from "./store/bill-expense-import-view.store";
import { BillSelectViewStore } from "./store/bill-select-view.store";
import { BillTimeImportViewStore } from "./store/bill-time-import-view.store";
import { BillViewStore } from "./store/bill-view.store";

const DefaultTableHeight = 500;

@observer
export default class BillLayout extends React.Component {
    @inject private viewStore!: BillViewStore;
    @inject private billSelectStore!: BillSelectViewStore;
    @inject private clientSelectStore!: BillClientSelectViewStore;
    @inject private importTimeStore!: BillTimeImportViewStore;
    @inject private importExpenseStore!: BillExpenseImportViewStore;

    componentDidMount() {
        this.viewStore.init();
    }

    componentWillUnmount() {
        this.clientSelectStore.setSearch("");
    }

    render() {
        const {
            showImportModal: showImportTimeModal,
            setShowImportModal,
            importing: importingTime,
            imported: importedTime,
            validating: validatingTime,
            validated: validatedTime,
            updateFile: updateTimeFile,
            validateFile: validateTimeFile,
        } = this.importTimeStore;
        const {
            showImportModal: showImportExpenseModal,
            setShowImportModal: setShowExpenseImportModal,
            importing: importingExpense,
            imported: importedExpense,
            validating: validatingExpense,
            validated: validatedExpense,
            updateFile: updateExpenseFile,
            validateFile: validateExpenseFile,
        } = this.importExpenseStore;
        const { search, selected, setSearch, setSelected } = this.clientSelectStore;
        const { selected: selectedBill } = this.billSelectStore;
        const {
            isInitializing,
            generatingReport,
            clients,
            accordionItem: itemKey,
            setAccordionItem,
            detailSurveyModel,
            onSurveyCompleted,
            timeForSelectedClient,
            expensesForSelectedClient,
            generateReport,
        } = this.viewStore;

        const accordionItem =
            selectedBill?.locked && ["bill-expenses", "bill-time", "bill-discounts"].includes(itemKey)
                ? "none"
                : itemKey;

        return (
            <React.Fragment>
                <DefaultImportModal
                    title="Time"
                    importBtnText="Import"
                    importedBtnText="Imported"
                    show={showImportTimeModal && !!selectedBill}
                    onSetShow={setShowImportModal}
                    importing={importingTime}
                    imported={importedTime}
                    validating={validatingTime}
                    validated={validatedTime}
                    onImportClick={(file: any) => updateExpenseFile(selectedBill!, file)}
                    onValidateClick={(file: any) => validateTimeFile(selectedBill!, file)}
                />
                <DefaultImportModal
                    title="Expenses"
                    importBtnText="Import"
                    importedBtnText="Imported"
                    show={showImportExpenseModal && !!selectedBill}
                    onSetShow={setShowExpenseImportModal}
                    importing={importingExpense}
                    imported={importedExpense}
                    validating={validatingExpense}
                    validated={validatedExpense}
                    onImportClick={(file: any) => updateTimeFile(selectedBill!, file)}
                    onValidateClick={(file: any) => validateExpenseFile(selectedBill!, file)}
                />
                <div>
                    <DefaultPageHeader
                        title="Bill"
                        description="View, edit, and manage client bills.  Select a client to get started."
                    />
                    <Accordion activeKey={accordionItem} className="bill-accordion p-0 m-0 border-0">
                        <BillAccordionItemBillSelect
                            clients={clients}
                            clientSearch={search}
                            isInitializing={isInitializing}
                            selectedClient={selected}
                            onSetClientSelected={setSelected}
                            onClientSearch={setSearch}
                            maxTableHeight={DefaultTableHeight}
                            selectedBill={selectedBill}
                            selectedItem={accordionItem}
                            setSelectedItem={setAccordionItem}
                        />
                        <BillAccordionItemBillDetails
                            selectedBill={selectedBill}
                            selectedItem={accordionItem}
                            setSelectedItem={setAccordionItem}
                            detailSurveyModel={detailSurveyModel}
                            onSurveyCompleted={(bill) => {
                                onSurveyCompleted(bill);
                                setAccordionItem("bill-discounts");
                            }}
                        />
                        <BillAccordionItemDiscountSelect
                            selectedBill={selectedBill!}
                            selectedItem={accordionItem}
                            setSelectedItem={setAccordionItem}
                            onSave={() => setAccordionItem("bill-time")}
                        />
                        <BillAccordionItemTimeSelect
                            isInitializing={isInitializing}
                            selectedBill={selectedBill!}
                            selectedItem={accordionItem}
                            setSelectedItem={setAccordionItem}
                            timeEntries={
                                timeForSelectedClient.filter((time) => selectedBill?.timeIds.includes(time.id)) ?? []
                            }
                            maxTableHeight={DefaultTableHeight}
                            onSave={() => setAccordionItem("bill-expenses")}
                        />
                        <BillAccordionItemExpenseSelect
                            isInitializing={isInitializing}
                            selectedBill={selectedBill!}
                            selectedItem={accordionItem}
                            setSelectedItem={setAccordionItem}
                            expenses={
                                expensesForSelectedClient.filter((x) => selectedBill?.expenseIds.includes(x.id)) ?? []
                            }
                            maxTableHeight={DefaultTableHeight}
                            onSave={() => setAccordionItem("none")}
                        />
                        <div className="d-flex justify-content-between border w-100 align-items-center mt-3 p-3">
                            <div className="w-100">
                                <p
                                    className={classNames("font-weight-bold mb-0", {
                                        "text-disabled": accordionItem !== "none" || !selectedBill,
                                        "text-secondary": accordionItem === "none" && !!selectedBill,
                                    })}
                                >
                                    Generate Bill
                                </p>
                            </div>
                            <Button
                                disabled={accordionItem !== "none" || !selectedBill || generatingReport}
                                variant="primary-outline"
                                className="rounded-0"
                                size="sm"
                                onClick={() => generateReport(selectedBill)}
                            >
                                <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
                                {generatingReport ? (
                                    <Spinner size="sm" animation="border" variant="primary" className="ml-1" />
                                ) : (
                                    " Generate"
                                )}
                            </Button>
                        </div>
                    </Accordion>
                </div>
            </React.Fragment>
        );
    }
}
