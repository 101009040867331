import { inject } from "react-ioc";
import ExpenseTypeApi, { ExpenseTypeApiModel } from "../api/expense-type.api";

export type ExpenseType = ExpenseTypeApiModel;

export class ExpenseTypeDataStore {
    @inject private api!: ExpenseTypeApi;

    get = async (): Promise<ExpenseType[]> => {
        const { data } = await this.api.get();
        return data;
    };

    edit = async (model: ExpenseType) => {
        const { data } = await this.api.edit({
            ...model,
        });
        return data;
    };

    add = async (model: ExpenseType) => {
        const { data } = await this.api.add({
            ...model,
        });
        return data;
    };
}
