import { action, makeObservable, observable } from "mobx";

export default class AppToastsViewStore {
    toasts: string[] = [];

    constructor() {
        makeObservable(this, {
            toasts: observable,
            addToast: action,
            removeToast: action,
        });
    }

    addToast = (text: string) => this.toasts.push(text);

    removeToast = (text: string) => {
        const index = this.toasts.findIndex((x) => x === text);
        if (~index) {
            this.toasts.splice(index, 1);
        }
    };
}
