export enum SubscriptionsTypes {
    UserList,
    ClientUserList,
    ClientList,
    TaskList,
    ExpenseTypeList,
    ExpenseList,
    TimeList,
}

export default class SubscriptionService {
    private subscriptions = new Map<SubscriptionsTypes, { id: number; action: () => Promise<void> }[]>([
        [SubscriptionsTypes.UserList, []],
        [SubscriptionsTypes.ClientUserList, []],
        [SubscriptionsTypes.ClientList, []],
        [SubscriptionsTypes.TaskList, []],
        [SubscriptionsTypes.ExpenseTypeList, []],
        [SubscriptionsTypes.ExpenseList, []],
        [SubscriptionsTypes.TimeList, []],
    ]);
    private counter = 0;

    publish = async (type: SubscriptionsTypes, skipSubscriptionIds?: number[]) => {
        this.subscriptions
            .get(type)!
            .filter((x) => !skipSubscriptionIds || !skipSubscriptionIds.includes(x.id))
            .map((x) => x.action)
            .forEach(async (x) => await x());
    };

    subscribe = (types: SubscriptionsTypes[], action: () => Promise<void>): number[] => {
        const subscriptionIds: number[] = [];

        types.forEach((type) => {
            this.counter++;
            this.subscriptions.get(type)!.push({ id: this.counter, action });
            subscriptionIds.push(this.counter);
        });

        return subscriptionIds;
    };
}
