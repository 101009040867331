import { AxiosResponse } from "axios";
import { inject } from "react-ioc";
import { NamedIdApiModel } from "../../common/api-utils";
import AuthorizedAxios from "../../common/authorized-axios";

class ClientApi {
    private controller = "clientapi";
    @inject axios!: AuthorizedAxios;

    get = async (): Promise<AxiosResponse<ClientFormApiModel>> => {
        return (await this.axios.createInstance()).get(`${this.controller}/get`);
    };

    add = async (model: EditClientApiModel): Promise<AxiosResponse<ClientApiModel>> => {
        return (await this.axios.createInstance()).post(`${this.controller}/add`, model, {
            responseType: "json",
        });
    };

    edit = async (model: EditClientApiModel): Promise<AxiosResponse<ClientApiModel>> => {
        return (await this.axios.createInstance()).post(`${this.controller}/edit`, model, {
            responseType: "json",
        });
    };
}

export interface ClientApiModel extends EditClientApiModel {}

export interface EditClientApiModel {
    id: number;
    name: string;
    displayName: string;

    address?: string;
    chargeable: boolean;

    taskTypeIds: number[];

    billInstructions: BillInstructions;
    customBillingInstructions: string | undefined;
}

export enum BillInstructions {
    default = 0,
    check = 1,
    other = 2,
}

export interface ClientFormApiModel {
    clients: ClientApiModel[];
    taskTypes: NamedIdApiModel[];
}

export default ClientApi;
