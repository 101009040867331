import { AxiosResponse } from "axios";
import { inject } from "react-ioc";
import {
    ClientUserBasicApiModel,
    DateRangeApiModel,
    DownloadFileFromResponse,
    FileApiModel,
    NamedIdApiModel,
} from "../../common/api-utils";
import AuthorizedAxios from "../../common/authorized-axios";

class ExpenseApi {
    private controller = "expenseapi";
    @inject axios!: AuthorizedAxios;

    get = async (): Promise<AxiosResponse<ExpenseFormApiModel>> => {
        return (await this.axios.createInstance()).get(`${this.controller}/get`);
    };

    add = async (model: EditExpenseApiModel): Promise<AxiosResponse<ExpenseApiModel>> => {
        return (await this.axios.createInstance()).post(`${this.controller}/add`, model, {
            responseType: "json",
        });
    };

    edit = async (model: EditExpenseApiModel): Promise<AxiosResponse<ExpenseApiModel>> => {
        return (await this.axios.createInstance()).post(`${this.controller}/edit`, model, {
            responseType: "json",
        });
    };

    import = async (model: FileApiModel): Promise<AxiosResponse<string[]>> => {
        const form = new FormData();
        form.append("fileName", model.fileName);
        form.append("formFile", model.formFile);

        return (await this.axios.createInstance()).post(`${this.controller}/import`, form, {
            responseType: "json",
        });
    };

    downloadImportTemplate = async (): Promise<void> => {
        const response = await (
            await this.axios.createInstance()
        ).get<any>(`${this.controller}/downloadImportTemplate`, {
            responseType: "blob",
        });

        DownloadFileFromResponse(response);
    };

    downloadInRange = async (model: DateRangeApiModel): Promise<void> => {
        const response = await (
            await this.axios.createInstance()
        ).post<any>(`${this.controller}/downloadInRange`, model, {
            responseType: "blob",
        });

        DownloadFileFromResponse(response);
    };

    validate = async (model: FileApiModel): Promise<AxiosResponse<string[]>> => {
        const form = new FormData();
        form.append("fileName", model.fileName);
        form.append("formFile", model.formFile);

        return (await this.axios.createInstance()).post(`${this.controller}/validate`, form, {
            responseType: "json",
        });
    };
}

interface ExpenseFormApiModel {
    expenses: ExpenseApiModel[];

    clientUsers: ClientUserBasicApiModel[];
    expenseTypes: NamedIdApiModel[];
    locations: NamedIdApiModel[];
}

export interface ExpenseApiModel extends EditExpenseApiModel {}

interface EditExpenseApiModel {
    id: number;
    active: boolean;

    clientUserId: number;
    expenseTypeId: number;
    participantUserIds?: number[];

    date: Date;
    expenseMonth?: Date;
    billedMonth?: Date;

    amount: number;
    location?: number;

    description: string;
}

export default ExpenseApi;
