import { inject } from "react-ioc";
import { CreateDropdownChoices, DropdownChoice } from "../../common/api-utils";
import ClientUserApi, { ClientUserApiModel } from "../api/client-user.api";

export type ClientUser = ClientUserApiModel;

export class ClientUserDataStore {
    @inject private api!: ClientUserApi;

    get = async (): Promise<{
        clientUsers: ClientUser[];
        classTypes: DropdownChoice[];
        users: DropdownChoice[];
        clients: DropdownChoice[];
    }> => {
        const { data } = await this.api.get();
        return {
            clientUsers: data.clientUsers,
            classTypes: CreateDropdownChoices(data.classTypes),
            users: CreateDropdownChoices(data.users),
            clients: CreateDropdownChoices(data.clients),
        };
    };

    edit = async (model: ClientUser) => {
        if (!model.cap) {
            model.cap = undefined;
        }

        const { data } = await this.api.edit({
            ...model,
        });
        return data;
    };

    add = async (model: ClientUser) => {
        const { data } = await this.api.add({
            ...model,
        });
        return data;
    };
}
