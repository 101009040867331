import { AxiosResponse } from "axios";
import { inject } from "react-ioc";
import AuthorizedAxios from "../../common/authorized-axios";

class TaskTypeApi {
    private controller = "tasktypeapi";
    @inject axios!: AuthorizedAxios;

    get = async (): Promise<AxiosResponse<TaskTypeApiModel[]>> => {
        return (await this.axios.createInstance()).get(`${this.controller}/get`);
    };

    add = async (model: EditTaskTypeApiModel): Promise<AxiosResponse<TaskTypeApiModel>> => {
        return (await this.axios.createInstance()).post(`${this.controller}/add`, model, {
            responseType: "json",
        });
    };

    edit = async (model: EditTaskTypeApiModel): Promise<AxiosResponse<TaskTypeApiModel>> => {
        return (await this.axios.createInstance()).post(`${this.controller}/edit`, model, {
            responseType: "json",
        });
    };
}

export interface TaskTypeApiModel extends EditTaskTypeApiModel {}

export interface EditTaskTypeApiModel {
    id: number;
    name: string;
    active: boolean;
    sortOrder?: number;
}

export default TaskTypeApi;
