import { action, makeObservable, observable, runInAction } from "mobx";
import { inject } from "react-ioc";
import { SortByName } from "../../common/api-utils";
import SubscriptionService, { SubscriptionsTypes } from "../../common/subscription-service";
import { TaskType, TaskTypeDataStore } from "./tasktype-data.store";

export class TaskTypeViewStore {
    @inject private dataStore!: TaskTypeDataStore;
    @inject private subscriptionService!: SubscriptionService;

    isInitializing?: boolean;
    items: TaskType[] = [];
    editingItem?: TaskType;
    search = "";
    private isCreating = false;

    constructor() {
        makeObservable(this, {
            isInitializing: observable,
            items: observable,
            editingItem: observable,
            search: observable,
            init: action,
            startEditItem: action,
            startAddItem: action,
            setSearch: action,
        });
    }

    init = async () => {
        if (this.isInitializing !== undefined) {
            return;
        }

        this.isInitializing = true;
        try {
            const data = await this.dataStore.get();

            runInAction(() => {
                this.items = SortByName(data);
                this.isInitializing = false;
            });
        } catch (e) {
            runInAction(() => (this.isInitializing = undefined));
            throw e;
        }
    };

    startEditItem = (taskType?: TaskType) => {
        this.editingItem = taskType;
        this.isCreating = false;
    };

    startAddItem = () => {
        this.editingItem = { id: 0, name: "", active: true };
        this.isCreating = true;
    };

    saveItem = async (taskType: TaskType) => {
        const index = this.items.findIndex((x) => x.id === taskType.id);

        try {
            const updated = this.isCreating ? await this.dataStore.add(taskType) : await this.dataStore.edit(taskType);

            runInAction(() => {
                if (~index) {
                    this.items.splice(index, 1, updated);
                } else {
                    this.items.push(updated);
                }
                this.startEditItem(undefined);
            });
        } catch (e) {
            runInAction(() => {
                this.startEditItem(undefined);
            });
            throw e;
        }

        this.subscriptionService.publish(SubscriptionsTypes.TaskList);
    };

    setSearch = (value: string) => {
        this.search = value.toLowerCase();
    };
}
