import { inject } from "react-ioc";
import { ConvertFromApiDate, ConvertToDate } from "../../common/api-utils";
import UserApi, { BillingUserApiModel, RoleType } from "../api/user.api";

export type User = {
    id: number;
    name: string;
    active: boolean;
    role: string;
    email: string;
    title?: string;

    eligibleHours: number;
    hireDate?: string;
    hasLoginAccount: boolean;
};

export class UserDataStore {
    @inject private api!: UserApi;

    private roleNames = new Map([
        [RoleType.None, "None"],
        [RoleType.Admin, "Admin"],
        [RoleType.User, "User"],
    ]);

    get = async () => {
        const { data } = await this.api.get();
        return data.map((x) => this.createUser(x));
    };

    edit = async (model: User) => {
        const { data } = await this.api.edit(this.createApiUser(model));
        return this.createUser(data);
    };

    add = async (model: User) => {
        const { data } = await this.api.add(this.createApiUser(model));
        return this.createUser(data);
    };

    private createApiUser = (user: User) => {
        return {
            ...user,
            role: Array.from(this.roleNames.entries()).find((x) => x[1] === user.role)?.[0] ?? RoleType.None,
            hireDate: ConvertToDate(user.hireDate),
        };
    };

    private createUser = (user: BillingUserApiModel) => {
        return {
            ...user,
            role: this.roleNames.get(user.role) ?? "None",
            hireDate: ConvertFromApiDate(user.hireDate),
        };
    };
}
