import { AxiosResponse } from "axios";
import { inject } from "react-ioc";
import AuthorizedAxios from "../../common/authorized-axios";

class UserApi {
    private controller = "billinguserapi";
    @inject axios!: AuthorizedAxios;

    get = async (): Promise<AxiosResponse<BillingUserApiModel[]>> => {
        return (await this.axios.createInstance()).get(`${this.controller}/get`);
    };

    edit = async (model: EditBillingUserApiModel): Promise<AxiosResponse<BillingUserApiModel>> => {
        return (await this.axios.createInstance()).post(`${this.controller}/edit`, model, {
            responseType: "json",
        });
    };

    add = async (model: EditBillingUserApiModel): Promise<AxiosResponse<BillingUserApiModel>> => {
        return (await this.axios.createInstance()).post(`${this.controller}/add`, model, {
            responseType: "json",
        });
    };
}

export interface BillingUserApiModel extends EditBillingUserApiModel {
    hasLoginAccount: boolean;
}

export enum RoleType {
    None = 0,
    User = 1,
    Admin = 255,
}

export interface EditBillingUserApiModel {
    id: number;
    name: string;
    email: string;
    title?: string;
    active: boolean;
    role: RoleType;

    hireDate?: Date;
    eligibleHours: number;
}

export default UserApi;
