import { faFileDownload, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { Alert, Button, Table } from "react-bootstrap";
import { useInstance } from "react-ioc";
import DefaultLoader from "../../common/default-loader";
import DefaultSaveButton from "../../common/default-save-button";
import { DollarFormatter } from "../../common/formatters";
import SearchBar, { SearchBarProps } from "../../common/search-bar";
import "../bill-layout.scss";
import { Bill, Expense } from "../store/bill-data.store";
import { BillExpenseImportViewStore } from "../store/bill-expense-import-view.store";
import { BillExpenseSelectViewStore } from "../store/bill-expense-select-view.store";
import { AccordionItem } from "../store/bill-view.store";
import BillAccordionItem from "./bill-accordion-item";

interface ExpenseSelectProps extends SearchBarProps {
    maxTableHeight?: number;

    expenses: Expense[];
    matchesSearch: (expense: Expense) => boolean;
}

const TableHeader = (
    <thead className="user-select-none">
        <tr>
            <th className="border-top-0 header-name">Name</th>
            <th className="border-top-0 header-date">Date</th>
            <th className="border-top-0">Amount</th>
            <th className="border-top-0">Description</th>
        </tr>
    </thead>
);

const EditableTableCells = (props: { item: Expense }) => {
    return (
        <React.Fragment>
            <td hidden>{props.item.id}</td>
            <td>{props.item.userName}</td>
            <td>{props.item.date}</td>
            <td>{props.item.amount}</td>
            <td>{props.item.description}</td>
        </React.Fragment>
    );
};

const ExpenseSelect = observer((props: ExpenseSelectProps) => {
    return (
        <React.Fragment>
            <SearchBar fullWidth search={props.search} setSearch={props.setSearch} />
            <Table
                borderless
                hover
                striped
                className={classNames("bill-table d-block user-select-none", {
                    "overflow-y-scroll": !!props.maxTableHeight,
                })}
                style={{
                    maxHeight: props.maxTableHeight,
                }}
            >
                {TableHeader}
                <tbody>
                    {props.expenses
                        .filter((x) => props.matchesSearch(x))
                        .map((item) => (
                            <tr>
                                <EditableTableCells item={item} />
                            </tr>
                        ))}
                </tbody>
            </Table>
        </React.Fragment>
    );
});

interface BillAccordionItemExpenseSelectProps {
    isInitializing?: boolean;
    maxTableHeight?: number;

    selectedItem: AccordionItem;
    setSelectedItem: (item: AccordionItem) => void;

    selectedBill: Bill;

    expenses: Expense[];

    onSave: () => void;
}

const BillAccordionItemExpenseSelect = observer((props: BillAccordionItemExpenseSelectProps) => {
    const { setShowImportModal, exportExpenseFile } = useInstance(BillExpenseImportViewStore);
    const { search, setSearch, matchesSearch } = useInstance(BillExpenseSelectViewStore);

    return (
        <BillAccordionItem
            className="mt-3"
            title="Expense Selection"
            description="Please export the list of expenses, update them in Excel, then re-import. This exported list will include all expenses not assigned to any bill, as well as all expenses already assigned to this bill. Below is the list of expenses this bill already contains:"
            itemType="bill-expenses"
            selectedItemType={props.selectedItem}
            showEdit={!props.selectedBill?.locked && props.selectedItem === "none"}
            showSummary={props.selectedItem === "none"}
            onEdit={props.setSelectedItem}
            headerSummary={
                <div className="w-100 d-flex mt-2">
                    <div className="w-25">
                        <p className="mb-1 text-subtle">Expenses</p>
                        <p>{props.expenses?.length ?? 0}</p>
                    </div>
                    <div className="w-25">
                        <p className="mb-1 text-subtle">Total Amount</p>
                        <p>{DollarFormatter.format(props.expenses?.reduce((a, b) => a + b.amount, 0) ?? 0)}</p>
                    </div>
                    <div className="w-25">
                        <p className="mb-1 text-subtle">Users</p>
                        <div className="d-flex flex-column">
                            {props.expenses
                                ?.map((x) => x.userName)
                                .filter((v, i, a) => a.indexOf(v) === i)
                                .map((x) => {
                                    return <p className="mb-1">{x}</p>;
                                })}
                        </div>
                    </div>
                    <div className="w-25">
                        <p className="mb-1 text-subtle">Task Types</p>
                        <div className="d-flex flex-column">
                            {props.expenses
                                ?.map((x) => x.expenseTypeName)
                                .filter((v, i, a) => a.indexOf(v) === i)
                                .map((x) => {
                                    return <p className="mb-1">{x}</p>;
                                })}
                        </div>
                    </div>
                </div>
            }
            body={
                <div className="w-100 d-flex flex-column">
                    {props.expenses?.length ? (
                        <ExpenseSelect
                            search={search}
                            setSearch={setSearch}
                            matchesSearch={matchesSearch}
                            maxTableHeight={props.maxTableHeight}
                            expenses={props.isInitializing ? [] : props.expenses}
                        />
                    ) : (
                        <Alert variant="info">
                            <p className="m-0">No expenses are currently assigned to this bill.</p>
                        </Alert>
                    )}
                    {props.isInitializing ? <DefaultLoader className="mt-4" /> : undefined}
                    <div className="w-100 d-flex">
                        <Button
                            variant="secondary-outline"
                            className="rounded-0 ml-auto"
                            size="sm"
                            onClick={() => exportExpenseFile(props.selectedBill)}
                        >
                            <FontAwesomeIcon icon={faFileDownload} className="mr-1" /> Export
                        </Button>
                        <Button
                            variant="secondary-outline"
                            className="rounded-0 mx-2"
                            size="sm"
                            onClick={() => setShowImportModal(true)}
                        >
                            <FontAwesomeIcon icon={faFileUpload} className="mr-1" /> Import
                        </Button>
                        <DefaultSaveButton onClick={props.onSave} />
                    </div>
                </div>
            }
        />
    );
});

export default BillAccordionItemExpenseSelect;
