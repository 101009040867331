import { action, makeObservable, observable, runInAction } from "mobx";
import { inject } from "react-ioc";
import { SortByName } from "../../common/api-utils";
import SubscriptionService, { SubscriptionsTypes } from "../../common/subscription-service";
import { BillInstructions } from "../api/client.api";
import { Client, ClientDataStore } from "./client-data.store";

const GenerateJson = (taskTypeOptions: string[]) => {
    return {
        elements: [
            {
                name: "displayName",
                type: "text",
                title: "What is the reporting name for this client?",
                placeHolder: "Bad Wolf Corporation, LLC",
                isRequired: true,
            },
            {
                name: "chargeable",
                type: "boolean",
                title: "Can time be entered for this client?",
                isRequired: true,
            },
            {
                name: "name",
                type: "text",
                title: "What is the short name for this client?",
                placeHolder: "Bad Wolf",
                isRequired: true,
            },
            {
                name: "address",
                type: "comment",
                title: "What is this client's address?",
                isRequired: true,
            },
            {
                name: "taskTypeNames",
                type: "tagbox",
                choices: taskTypeOptions,
                choicesOrder: "asc",
                title: "Which types of tasks apply to this client?",
            },
            {
                name: "billInstructions",
                type: "dropdown",
                choices: [
                    { value: BillInstructions.default, text: "Standard Wire (default)" },
                    { value: BillInstructions.check, text: "Check - Valley Stream" },
                    { value: BillInstructions.other, text: "Other" },
                ],
                title: "What billing instructions should used for this client?",
                isRequired: true,
            },
            {
                name: "customBillingInstructions",
                type: "comment",
                title: "What are the custom billing instructions?",
                requiredIf: `{billInstructions} = '${BillInstructions.other}'`,
                visibleIf: `{billInstructions} = '${BillInstructions.other}'`,
            },
        ],
        showQuestionNumbers: "off",
        completedHtml: "<p>Updating...</p>",
        completeText: "Save",
    };
};

export class ClientViewStore {
    @inject private dataStore!: ClientDataStore;
    @inject private subscriptionService!: SubscriptionService;

    isInitializing?: boolean;
    items: Client[] = [];
    editingItem?: Client;
    search = "";
    editClientJson: any = {};
    private isCreatingClient = false;
    private subscriptionIds: number[] = [];

    constructor() {
        makeObservable(this, {
            isInitializing: observable,
            items: observable,
            editingItem: observable,
            search: observable,
            init: action,
            startEditItem: action,
            startAddItem: action,
            setSearch: action,
        });
    }

    init = async () => {
        if (this.isInitializing !== undefined) {
            return;
        }

        try {
            await this.initInteral();
        } catch (e) {
            runInAction(() => (this.isInitializing = undefined));
            throw e;
        } finally {
            this.subscriptionIds = this.subscriptionService.subscribe(
                [SubscriptionsTypes.TaskList, SubscriptionsTypes.ClientList],
                this.initInteral
            );
        }
    };

    private initInteral = async () => {
        this.isInitializing = true;
        const data = await this.dataStore.get();

        runInAction(() => {
            this.editClientJson = GenerateJson(SortByName(data.taskTypes).map((x) => x.name));
            this.items = SortByName(data.clients);
            this.isInitializing = false;
        });
    };

    startEditItem = (client?: Client) => {
        this.editingItem = client;
        this.isCreatingClient = false;
    };

    startAddItem = () => {
        this.editingItem = {
            id: 0,
            name: "",
            displayName: "",
            chargeable: true,
            taskTypeNames: [],
            billInstructions: BillInstructions.default,
            customBillingInstructions: undefined,
        };
        this.isCreatingClient = true;
    };

    saveItem = async (client: Client) => {
        const index = this.items.findIndex((x) => x.id === client.id);

        try {
            const updated = this.isCreatingClient
                ? await this.dataStore.add(client)
                : await this.dataStore.edit(client);

            runInAction(() => {
                if (~index) {
                    this.items.splice(index, 1, updated);
                } else {
                    this.items.push(updated);
                }
                this.startEditItem(undefined);
            });
        } catch (e) {
            runInAction(() => {
                this.startEditItem(undefined);
            });
            throw e;
        }

        this.subscriptionService.publish(SubscriptionsTypes.ClientList, this.subscriptionIds);
    };

    setSearch = (value: string) => {
        this.search = value.toLowerCase();
    };
}
