import React from "react";
import { Container } from "react-bootstrap";
import { NavMenu } from "./navigation/nav-menu";

export class Layout extends React.Component {
    static displayName = Layout.name;

    render() {
        return (
            <div className="vh-100 w-100">
                <NavMenu />
                <Container>{this.props.children}</Container>
            </div>
        );
    }
}
