import { observer } from "mobx-react";
import React from "react";
import { useInstance } from "react-ioc";
import { TimeEntryViewStore } from "./store/time-entry-view.store";
import Calendar from "../common/external/react-calendar/components/calendar/Calendar";

const MsInADay = 1000 * 60 * 60 * 24;

const DaySelect = observer(() => {
    const { selectedDays, setSelectedDays, datesWithTimeEntry, lastSelected } = useInstance(TimeEntryViewStore);

    return (
        <Calendar
            className="rounded-0 shadow-none"
            size={400}
            startOfWeek={0}
            isMultiSelector
            onChange={(value) => setSelectedDays(value as Date[])}
            maxAllowedDate={new Date(Date.now() + MsInADay * 60)}
            value={selectedDays}
            highlights={datesWithTimeEntry}
            singleHighlight={lastSelected}
        />
    );
});

export default DaySelect;
