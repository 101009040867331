import React from "react";
import { Modal } from "react-bootstrap";
import { Survey, SurveyModel } from "survey-react";
import { TimeEntryFilter } from "./store/time-entry-view.store";

const TimeEntryFilterModal = (props: {
    show: boolean;
    filter?: TimeEntryFilter;
    json: object;
    onHide: () => void;
    onSave: (filter?: TimeEntryFilter) => void;
}) => {
    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Time - Filter Records</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <Survey
                    json={props.json}
                    data={{ ...props.filter }}
                    onComplete={(result: SurveyModel) => {
                        props.onSave(result.data);
                    }}
                />
            </Modal.Body>
        </Modal>
    );
};

export default TimeEntryFilterModal;
